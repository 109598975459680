/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMarketingToolsTypes
// ====================================================

export interface GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes {
  __typename: "MarketingToolsType";
  id: string;
  name: string;
}

export interface GetMarketingToolsTypes_authorized_partner_data {
  __typename: "PartnerDataType";
  marketingToolsTypes: GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes[];
}

export interface GetMarketingToolsTypes_authorized_partner {
  __typename: "PartnerQuery";
  data: GetMarketingToolsTypes_authorized_partner_data;
}

export interface GetMarketingToolsTypes_authorized {
  __typename: "AuthorizedQuery";
  partner: GetMarketingToolsTypes_authorized_partner;
}

export interface GetMarketingToolsTypes {
  authorized: GetMarketingToolsTypes_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMediaTypes
// ====================================================

export interface GetMediaTypes_authorized_partner_data_mediaTypes {
  __typename: "MediaType";
  id: string;
  name: string;
}

export interface GetMediaTypes_authorized_partner_data {
  __typename: "PartnerDataType";
  mediaTypes: GetMediaTypes_authorized_partner_data_mediaTypes[];
}

export interface GetMediaTypes_authorized_partner {
  __typename: "PartnerQuery";
  data: GetMediaTypes_authorized_partner_data;
}

export interface GetMediaTypes_authorized {
  __typename: "AuthorizedQuery";
  partner: GetMediaTypes_authorized_partner;
}

export interface GetMediaTypes {
  authorized: GetMediaTypes_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerProgramRegistrationSources
// ====================================================

export interface PartnerProgramRegistrationSources_authorized_manager_data_partnerProgramRegistrationSource {
  __typename: "PartnerProgramRegistrationSource";
  id: number;
  name: string;
}

export interface PartnerProgramRegistrationSources_authorized_manager_data {
  __typename: "ManagerDataType";
  partnerProgramRegistrationSource: PartnerProgramRegistrationSources_authorized_manager_data_partnerProgramRegistrationSource[];
}

export interface PartnerProgramRegistrationSources_authorized_manager {
  __typename: "ManagerQuery";
  data: PartnerProgramRegistrationSources_authorized_manager_data;
}

export interface PartnerProgramRegistrationSources_authorized {
  __typename: "AuthorizedQuery";
  manager: PartnerProgramRegistrationSources_authorized_manager;
}

export interface PartnerProgramRegistrationSources {
  authorized: PartnerProgramRegistrationSources_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRegistrationSources
// ====================================================

export interface GetRegistrationSources_registrationSources {
  __typename: "RegistrationSource";
  id: number;
  name: string;
}

export interface GetRegistrationSources {
  registrationSources: GetRegistrationSources_registrationSources[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPartnerStatistics
// ====================================================

export interface ResetPartnerStatistics_authorized_partner {
  __typename: "PartnerMutation";
  resetStatistics: boolean;
}

export interface ResetPartnerStatistics_authorized {
  __typename: "AuthorizedMutation";
  partner: ResetPartnerStatistics_authorized_partner | null;
}

export interface ResetPartnerStatistics {
  authorized: ResetPartnerStatistics_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignOut
// ====================================================

export interface SignOut_authorized {
  __typename: "AuthorizedMutation";
  signOut: boolean;
}

export interface SignOut {
  authorized: SignOut_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignInWithTwoFA
// ====================================================

export interface SignInWithTwoFA_authorization_signInWithTwoFA {
  __typename: "User";
  id: string;
  name: string;
  csrf: string;
  affiliateId: string;
  roles: (string | null)[] | null;
}

export interface SignInWithTwoFA_authorization {
  __typename: "AuthorizationMutation";
  signInWithTwoFA: SignInWithTwoFA_authorization_signInWithTwoFA;
}

export interface SignInWithTwoFA {
  authorization: SignInWithTwoFA_authorization;
}

export interface SignInWithTwoFAVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignOutBackToManager
// ====================================================

export interface SignOutBackToManager_authorized_signOutBackToManager {
  __typename: "User";
  id: string;
}

export interface SignOutBackToManager_authorized {
  __typename: "AuthorizedMutation";
  signOutBackToManager: SignOutBackToManager_authorized_signOutBackToManager;
}

export interface SignOutBackToManager {
  authorized: SignOutBackToManager_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerSites
// ====================================================

export interface PartnerSites_authorized_partnerAndManager_data_sites {
  __typename: "Site";
  id: number;
  name: string;
  hidden: boolean;
}

export interface PartnerSites_authorized_partnerAndManager_data {
  __typename: "PartnerAndManagerDataType";
  sites: PartnerSites_authorized_partnerAndManager_data_sites[];
}

export interface PartnerSites_authorized_partnerAndManager {
  __typename: "PartnerAndManagerQuery";
  data: PartnerSites_authorized_partnerAndManager_data;
}

export interface PartnerSites_authorized {
  __typename: "AuthorizedQuery";
  partnerAndManager: PartnerSites_authorized_partnerAndManager;
}

export interface PartnerSites {
  authorized: PartnerSites_authorized;
}

export interface PartnerSitesVariables {
  filter: SitesFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ServerTime
// ====================================================

export interface ServerTime_server {
  __typename: "ServerPayload";
  time: number;
}

export interface ServerTime {
  server: ServerTime_server | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_user_hiddenColumns {
  __typename: "HiddenColumns";
  Graph: (string | null)[] | null;
  Statistic: (string | null)[] | null;
  TopPartners: (string | null)[] | null;
  TopPromo: (string | null)[] | null;
  Quick: (string | null)[] | null;
  Full: (string | null)[] | null;
  Promo: (string | null)[] | null;
  Players: (string | null)[] | null;
  SubPartner: (string | null)[] | null;
  Advertising: (string | null)[] | null;
}

export interface User_user {
  __typename: "User";
  id: string;
  name: string;
  csrf: string;
  affiliateId: string;
  roles: (string | null)[] | null;
  hiddenColumns: User_user_hiddenColumns;
}

export interface User {
  user: User_user | null;
}

export interface UserVariables {
  updateTokenSwitcher: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NewPassword
// ====================================================

export interface NewPassword_authorization {
  __typename: "AuthorizationMutation";
  newPassword: boolean;
}

export interface NewPassword {
  authorization: NewPassword_authorization;
}

export interface NewPasswordVariables {
  password: string;
  rePassword: string;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCampaigns
// ====================================================

export interface GetCampaigns_authorized_partnerAndManager_data_campaigns {
  __typename: "Campaign";
  id: string;
  name: string;
  currency: number;
  domain: string | null;
  mediaId: string | null;
  directLink: boolean;
  hasApk: boolean;
  onlyMainPage: boolean;
}

export interface GetCampaigns_authorized_partnerAndManager_data {
  __typename: "PartnerAndManagerDataType";
  campaigns: GetCampaigns_authorized_partnerAndManager_data_campaigns[];
}

export interface GetCampaigns_authorized_partnerAndManager {
  __typename: "PartnerAndManagerQuery";
  data: GetCampaigns_authorized_partnerAndManager_data;
}

export interface GetCampaigns_authorized {
  __typename: "AuthorizedQuery";
  partnerAndManager: GetCampaigns_authorized_partnerAndManager;
}

export interface GetCampaigns {
  authorized: GetCampaigns_authorized;
}

export interface GetCampaignsVariables {
  partnerId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCommissionGroups
// ====================================================

export interface GetCommissionGroups_authorized_manager_main_commisionGroups {
  __typename: "CommisionGroup";
  id: number;
  name: string;
  currency: string;
  description: string;
  countOfPartners: number;
  status: Status;
}

export interface GetCommissionGroups_authorized_manager_main {
  __typename: "ManagerMainQuery";
  commisionGroups: GetCommissionGroups_authorized_manager_main_commisionGroups[];
}

export interface GetCommissionGroups_authorized_manager {
  __typename: "ManagerQuery";
  main: GetCommissionGroups_authorized_manager_main;
}

export interface GetCommissionGroups_authorized {
  __typename: "AuthorizedQuery";
  manager: GetCommissionGroups_authorized_manager;
}

export interface GetCommissionGroups {
  authorized: GetCommissionGroups_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CookiesPolicy
// ====================================================

export interface CookiesPolicy_partnersProgram {
  __typename: "PartnersProgramQuery";
  cookiesPolicy: string;
}

export interface CookiesPolicy {
  partnersProgram: CookiesPolicy_partnersProgram;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCountries
// ====================================================

export interface GetCountries_countries {
  __typename: "Country";
  id: number;
  name: string;
  code: string;
  phoneCode: string;
}

export interface GetCountries {
  countries: GetCountries_countries[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrenciesAdversitingPayments
// ====================================================

export interface GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments {
  __typename: "AdversitingPaymentsCurrency";
  id: number;
  name: string;
  baseRate: number | null;
  round: number | null;
}

export interface GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPayments";
  currencyAdvertisingPayments: GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments[];
}

export interface GetCurrenciesAdversitingPayments_authorized_manager_advertising {
  __typename: "ManagerAdvertisingQuery";
  advertisingPayments: GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments;
}

export interface GetCurrenciesAdversitingPayments_authorized_manager {
  __typename: "ManagerQuery";
  advertising: GetCurrenciesAdversitingPayments_authorized_manager_advertising;
}

export interface GetCurrenciesAdversitingPayments_authorized {
  __typename: "AuthorizedQuery";
  manager: GetCurrenciesAdversitingPayments_authorized_manager;
}

export interface GetCurrenciesAdversitingPayments {
  authorized: GetCurrenciesAdversitingPayments_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrencies
// ====================================================

export interface GetCurrencies_authorized_partnerAndManager_data_currencies {
  __typename: "PartnerAndManagerCurrency";
  id: string;
  name: string;
}

export interface GetCurrencies_authorized_partnerAndManager_data {
  __typename: "PartnerAndManagerDataType";
  currencies: GetCurrencies_authorized_partnerAndManager_data_currencies[];
}

export interface GetCurrencies_authorized_partnerAndManager {
  __typename: "PartnerAndManagerQuery";
  data: GetCurrencies_authorized_partnerAndManager_data;
}

export interface GetCurrencies_authorized {
  __typename: "AuthorizedQuery";
  partnerAndManager: GetCurrencies_authorized_partnerAndManager;
}

export interface GetCurrencies {
  authorized: GetCurrencies_authorized;
}

export interface GetCurrenciesVariables {
  partnerId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFaq
// ====================================================

export interface GetFaq_partnersProgram_faq_partitions_questions {
  __typename: "FaqQuestion";
  question: string;
  answer: string | null;
  image: string | null;
}

export interface GetFaq_partnersProgram_faq_partitions {
  __typename: "FaqPartition";
  sectionName: string | null;
  questions: GetFaq_partnersProgram_faq_partitions_questions[];
}

export interface GetFaq_partnersProgram_faq {
  __typename: "Faq";
  partitions: GetFaq_partnersProgram_faq_partitions[] | null;
}

export interface GetFaq_partnersProgram {
  __typename: "PartnersProgramQuery";
  faq: GetFaq_partnersProgram_faq;
}

export interface GetFaq {
  partnersProgram: GetFaq_partnersProgram;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCategories
// ====================================================

export interface GetCategories_siteCategories {
  __typename: "SiteCategory";
  id: number;
  name: string;
}

export interface GetCategories {
  siteCategories: GetCategories_siteCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHowDidYouKnowOptions
// ====================================================

export interface GetHowDidYouKnowOptions_howDidYouKnowOptions {
  __typename: "HowDidYouKnow";
  id: number;
  name: string;
}

export interface GetHowDidYouKnowOptions {
  howDidYouKnowOptions: GetHowDidYouKnowOptions_howDidYouKnowOptions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLanguages
// ====================================================

export interface GetLanguages_languages {
  __typename: "Language";
  id: number;
  name: string;
  code: string;
  nativeName: string;
}

export interface GetLanguages {
  languages: GetLanguages_languages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMessengers
// ====================================================

export interface GetMessengers_messengers {
  __typename: "Messenger";
  id: number;
  name: string;
  validationTemplate: string;
  isEnabled: boolean;
  placeholder: string;
}

export interface GetMessengers {
  messengers: GetMessengers_messengers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPartnersGroups
// ====================================================

export interface GetPartnersGroups_authorized_manager_main_partnersGroups_partnerGroups {
  __typename: "PartnersGroup";
  id: number;
  name: string;
  currencyId: number;
  countOfPartners: number;
}

export interface GetPartnersGroups_authorized_manager_main_partnersGroups {
  __typename: "PartnerGroupsResponse";
  partnerGroups: GetPartnersGroups_authorized_manager_main_partnersGroups_partnerGroups[];
  rowsCount: number;
}

export interface GetPartnersGroups_authorized_manager_main {
  __typename: "ManagerMainQuery";
  partnersGroups: GetPartnersGroups_authorized_manager_main_partnersGroups;
}

export interface GetPartnersGroups_authorized_manager {
  __typename: "ManagerQuery";
  main: GetPartnersGroups_authorized_manager_main;
}

export interface GetPartnersGroups_authorized {
  __typename: "AuthorizedQuery";
  manager: GetPartnersGroups_authorized_manager;
}

export interface GetPartnersGroups {
  authorized: GetPartnersGroups_authorized;
}

export interface GetPartnersGroupsVariables {
  query: PartnersQueryParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPaymentSystems
// ====================================================

export interface GetPaymentSystems_paymentSystems_fields {
  __typename: "PaymentSystemField";
  id: string;
  name: string;
  validationTemplate: string;
  required: boolean;
}

export interface GetPaymentSystems_paymentSystems {
  __typename: "PaymentSystem";
  id: number;
  code: string | null;
  name: string;
  isDisplay: boolean;
  fields: GetPaymentSystems_paymentSystems_fields[];
}

export interface GetPaymentSystems {
  paymentSystems: GetPaymentSystems_paymentSystems[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PrivacyPolicy
// ====================================================

export interface PrivacyPolicy_partnersProgram {
  __typename: "PartnersProgramQuery";
  privacyPolicy: string;
}

export interface PrivacyPolicy {
  partnersProgram: PrivacyPolicy_partnersProgram;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendFeedback
// ====================================================

export interface SendFeedback_partnersProgram {
  __typename: "PartnersProgramMutation";
  sendFeedback: boolean;
}

export interface SendFeedback {
  partnersProgram: SendFeedback_partnersProgram;
}

export interface SendFeedbackVariables {
  name: string;
  email: string;
  message: string;
  recaptcha: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TermsAndConditions
// ====================================================

export interface TermsAndConditions_partnersProgram {
  __typename: "PartnersProgramQuery";
  termsAndConditions: string;
}

export interface TermsAndConditions {
  partnersProgram: TermsAndConditions_partnersProgram;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPassword
// ====================================================

export interface ResetPassword {
  resetPassword: boolean;
}

export interface ResetPasswordVariables {
  filter: ResetPasswordFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignIn
// ====================================================

export interface SignIn_authorization_signIn_user {
  __typename: "User";
  id: string;
  name: string;
  csrf: string;
  affiliateId: string;
  roles: (string | null)[] | null;
}

export interface SignIn_authorization_signIn {
  __typename: "SignInResult";
  user: SignIn_authorization_signIn_user | null;
  twoFactorAuthNeeded: boolean;
}

export interface SignIn_authorization {
  __typename: "AuthorizationMutation";
  signIn: SignIn_authorization_signIn;
}

export interface SignIn {
  authorization: SignIn_authorization;
}

export interface SignInVariables {
  login: string;
  password: string;
  recaptcha: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignInLikePartnerWithSignOutIfNeeded
// ====================================================

export interface SignInLikePartnerWithSignOutIfNeeded_authorized_signInLikeAPartnerWithSignOutIfNeeded {
  __typename: "User";
  id: string;
}

export interface SignInLikePartnerWithSignOutIfNeeded_authorized {
  __typename: "AuthorizedMutation";
  signInLikeAPartnerWithSignOutIfNeeded: SignInLikePartnerWithSignOutIfNeeded_authorized_signInLikeAPartnerWithSignOutIfNeeded;
}

export interface SignInLikePartnerWithSignOutIfNeeded {
  authorized: SignInLikePartnerWithSignOutIfNeeded_authorized;
}

export interface SignInLikePartnerWithSignOutIfNeededVariables {
  partnerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckEmailFree
// ====================================================

export interface CheckEmailFree {
  checkEmail: boolean;
}

export interface CheckEmailFreeVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckLoginFree
// ====================================================

export interface CheckLoginFree {
  checkLogin: boolean;
}

export interface CheckLoginFreeVariables {
  login: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RegistrationData
// ====================================================

export interface RegistrationData_authorization_registrationData {
  __typename: "RegistrationData";
  RI: number | null;
}

export interface RegistrationData_authorization {
  __typename: "AuthorizationQuery";
  registrationData: RegistrationData_authorization_registrationData;
}

export interface RegistrationData {
  authorization: RegistrationData_authorization;
}

export interface RegistrationDataVariables {
  tag?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignUp
// ====================================================

export interface SignUp_authorization {
  __typename: "AuthorizationMutation";
  signUp: boolean;
}

export interface SignUp {
  authorization: SignUp_authorization;
}

export interface SignUpVariables {
  registrationInfo: RegistrationInfo;
  recaptcha: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPartnersProgram
// ====================================================

export interface GetPartnersProgram_partnersProgram_partnersProgram {
  __typename: "Program";
  programId: string;
  showLanding: boolean;
}

export interface GetPartnersProgram_partnersProgram {
  __typename: "PartnersProgramQuery";
  partnersProgram: GetPartnersProgram_partnersProgram_partnersProgram;
}

export interface GetPartnersProgram {
  partnersProgram: GetPartnersProgram_partnersProgram;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPartnersProgramGeneralInfo
// ====================================================

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_agentSiteInformation {
  __typename: "AgentSiteInformation";
  companyName: string;
  siteAddress: string;
  emailSupport: string;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_seoScriptsAndTags {
  __typename: "SEOSctiptsAndTags";
  verificationTags: string | null;
  successfullLoginScript: string | null;
  unsuccessfullLoginScript: string | null;
  successfullRegistrationScript: string | null;
  unsuccessfullRegistrationScript: string | null;
  trackingScripts: string | null;
  trackingNoScripts: string | null;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions {
  __typename: "Permissions";
  postbacks4: boolean;
  paymentsAndInvoices: boolean;
  paymentHistory: boolean;
  postbacks: boolean;
  tokensForGetingActualDomain: boolean;
  customPromocode: boolean;
  advertising: boolean;
  editIntroducedByField: boolean;
  rights: Rights[];
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_visibility_showingNews {
  __typename: "ShowingNews";
  showNews: boolean;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_visibility {
  __typename: "Visibility";
  hiddenPages: string[];
  showingNews: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_visibility_showingNews;
  becomeAnAffiliate: boolean;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_welcomeInfo_list_listItem {
  __typename: "WelcomeInfoTurple";
  message: string;
  formatKey: string | null;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_welcomeInfo_list {
  __typename: "WelcomeInfoItem";
  listItem: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_welcomeInfo_list_listItem[];
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_welcomeInfo {
  __typename: "WelcomeInfo";
  title: string[];
  signUpBtnText: string[];
  list: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_welcomeInfo_list[];
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_termsIcons {
  __typename: "TermsIcons";
  iconFamily: string;
  icon: string;
  title: string;
  annotation: string;
  size: number;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_capabilities {
  __typename: "Capabilities";
  capabilityNumber: string;
  capabilityTitle: string;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_partnersSeals {
  __typename: "PartnerSeal";
  imageAdress: string;
  ref: string;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_partnersLogos {
  __typename: "PartnersLogos";
  partnerBG: string;
  partnerImg: string;
  partnerLink: string;
  secondBG: boolean;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage {
  __typename: "LandingMainPage";
  importantPartnerTitle: string;
  currencyName: string | null;
  welcomeInfo: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_welcomeInfo;
  comission: string;
  termsIcons: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_termsIcons[];
  capabilities: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_capabilities[];
  partnersSeals: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_partnersSeals[];
  partnersLogos: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage_partnersLogos[];
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_partnerInfo {
  __typename: "PartnerInfo";
  minimalPaymentWeekly: string | null;
  minimalPaymentMonthly: string | null;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_buttons_default {
  __typename: "DefaultButtons";
  borderRadius: string;
  color: string;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_buttons_welcomeButton {
  __typename: "WelcomeButton";
  background: string;
  iconColor: string;
  backgroundOnHover1: string;
  backgroundOnHover2: string;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_buttons {
  __typename: "Buttons";
  default: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_buttons_default;
  welcomeButton: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_buttons_welcomeButton | null;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_stylesTheme_clientSideStyles {
  __typename: "ClientSideStyles";
  headerBgColor: string | null;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_stylesTheme {
  __typename: "StylesTheme";
  backgroundColor: string;
  frontBackgroundColor: string;
  secondBorderColor: string;
  additionalColor1: string;
  additionalColor2: string;
  additionalColor3: string;
  additionalColor4: string;
  additionalColor5: string;
  landingHeaderColor: string;
  backBackgroundColor: string;
  bodyColor: string;
  languageSwitcherBG: string;
  authFormColor: string;
  CookieNotificationBg: string;
  landingTextColor: string;
  textColor: string;
  mutedTextColor: string;
  authTextColor: string;
  accentColor: string;
  accentHoverColor: string;
  adminTitleColor: string;
  primaryColor: string;
  activeColor: string;
  asideMenuItemColor: string;
  clientSideStyles: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_stylesTheme_clientSideStyles | null;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram_generalInformation {
  __typename: "GeneralInformation";
  partnersProgramId: string;
  partnerProgramCompanyName: string;
  siteAddress: string;
  email: string | null;
  skype: string | null;
  telegramm: string | null;
  facebook: string | null;
  instagram: string | null;
  twitter: string | null;
  youtube: string | null;
  whatsApp: string | null;
  linkedin: string | null;
  viber: string | null;
  androidAppLinks: string | null;
  telegramBot: string | null;
  IOSAppLinks: string | null;
  copyrightPeriodStart: string;
  agentSiteInformation: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_agentSiteInformation;
  seoDomain: string | null;
  seoScriptsAndTags: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_seoScriptsAndTags;
  permissions: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions | null;
  visibility: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_visibility;
  landingMainPage: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_landingMainPage | null;
  partnerInfo: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_partnerInfo;
  buttons: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_buttons;
  stylesTheme: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_stylesTheme;
}

export interface GetPartnersProgramGeneralInfo_partnersProgram {
  __typename: "PartnersProgramQuery";
  generalInformation: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation;
}

export interface GetPartnersProgramGeneralInfo {
  partnersProgram: GetPartnersProgramGeneralInfo_partnersProgram;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTestimonials
// ====================================================

export interface GetTestimonials_partnersProgram_testimonials {
  __typename: "Testimonial";
  id: number;
  author: string | null;
  description: string | null;
  href: string | null;
  image: string;
}

export interface GetTestimonials_partnersProgram {
  __typename: "PartnersProgramQuery";
  testimonials: GetTestimonials_partnersProgram_testimonials[];
}

export interface GetTestimonials {
  partnersProgram: GetTestimonials_partnersProgram;
}

export interface GetTestimonialsVariables {
  params: TestimonialsParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendAdvice
// ====================================================

export interface SendAdvice_partnersProgram {
  __typename: "PartnersProgramMutation";
  sendAdvice: boolean;
}

export interface SendAdvice {
  partnersProgram: SendAdvice_partnersProgram;
}

export interface SendAdviceVariables {
  name: string;
  email: string;
  agreeUsePersonalData: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmEmail
// ====================================================

export interface ConfirmEmail_authorization {
  __typename: "AuthorizationMutation";
  confirmEmail: boolean;
}

export interface ConfirmEmail {
  authorization: ConfirmEmail_authorization;
}

export interface ConfirmEmailVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Contacts
// ====================================================

export interface Contacts_partnersProgram {
  __typename: "PartnersProgramQuery";
  contacts: string;
}

export interface Contacts {
  partnersProgram: Contacts_partnersProgram;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetActualNews
// ====================================================

export interface GetActualNews_partnersProgram_news_getActualNews_news {
  __typename: "GetNewsListResponseNews";
  id: number;
  title: string;
  image: string;
  PImage: string;
  shortText: string;
  url: string;
  isVisible: boolean;
  date: string;
  datePublick: string;
  metaTitle: string | null;
  metaDescription: string | null;
}

export interface GetActualNews_partnersProgram_news_getActualNews {
  __typename: "GetNewsListResponse";
  statusID: ReportStatus;
  news: GetActualNews_partnersProgram_news_getActualNews_news[] | null;
}

export interface GetActualNews_partnersProgram_news {
  __typename: "NewsQuery";
  getActualNews: GetActualNews_partnersProgram_news_getActualNews;
}

export interface GetActualNews_partnersProgram {
  __typename: "PartnersProgramQuery";
  news: GetActualNews_partnersProgram_news;
}

export interface GetActualNews {
  partnersProgram: GetActualNews_partnersProgram;
}

export interface GetActualNewsVariables {
  params: GetNewsParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCurrentNews
// ====================================================

export interface GetCurrentNews_partnersProgram_news_getCurrentNews_news {
  __typename: "GetNewsListResponseNews";
  id: number;
  title: string;
  image: string;
  PImage: string;
  shortText: string;
  mainText: string | null;
  url: string;
  isVisible: boolean;
  date: string;
  datePublick: string;
  metaTitle: string | null;
  metaDescription: string | null;
}

export interface GetCurrentNews_partnersProgram_news_getCurrentNews {
  __typename: "GetCurrentNewsResponse";
  statusID: ReportStatus;
  news: GetCurrentNews_partnersProgram_news_getCurrentNews_news | null;
}

export interface GetCurrentNews_partnersProgram_news {
  __typename: "NewsQuery";
  getCurrentNews: GetCurrentNews_partnersProgram_news_getCurrentNews;
}

export interface GetCurrentNews_partnersProgram {
  __typename: "PartnersProgramQuery";
  news: GetCurrentNews_partnersProgram_news;
}

export interface GetCurrentNews {
  partnersProgram: GetCurrentNews_partnersProgram;
}

export interface GetCurrentNewsVariables {
  params: GetNewsParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNewsList
// ====================================================

export interface GetNewsList_partnersProgram_news_getNewsList_news {
  __typename: "GetNewsListResponseNews";
  id: number;
  title: string;
  image: string;
  PImage: string;
  shortText: string;
  mainText: string | null;
  url: string;
  isVisible: boolean;
  date: string;
  datePublick: string;
  metaTitle: string | null;
  metaDescription: string | null;
}

export interface GetNewsList_partnersProgram_news_getNewsList {
  __typename: "GetNewsListResponse";
  statusID: ReportStatus;
  news: GetNewsList_partnersProgram_news_getNewsList_news[] | null;
}

export interface GetNewsList_partnersProgram_news {
  __typename: "NewsQuery";
  getNewsList: GetNewsList_partnersProgram_news_getNewsList;
}

export interface GetNewsList_partnersProgram {
  __typename: "PartnersProgramQuery";
  news: GetNewsList_partnersProgram_news;
}

export interface GetNewsList {
  partnersProgram: GetNewsList_partnersProgram;
}

export interface GetNewsListVariables {
  params: GetNewsListParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnsubscribeFromEmail
// ====================================================

export interface UnsubscribeFromEmail_partnersProgram {
  __typename: "PartnersProgramMutation";
  unsubscribeFromEmail: boolean;
}

export interface UnsubscribeFromEmail {
  partnersProgram: UnsubscribeFromEmail_partnersProgram;
}

export interface UnsubscribeFromEmailVariables {
  oneOffToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdvertisementPaymentsFiles
// ====================================================

export interface AdvertisementPaymentsFiles_authorized_manager_advertising_advertisingPayments_advertisingPaymentsFiles_files {
  __typename: "AdvertisingPaymentFile";
  id: number;
  fileName: string;
  dateOfCreation: string;
}

export interface AdvertisementPaymentsFiles_authorized_manager_advertising_advertisingPayments_advertisingPaymentsFiles {
  __typename: "AdvertisementPaymentsFiles";
  files: AdvertisementPaymentsFiles_authorized_manager_advertising_advertisingPayments_advertisingPaymentsFiles_files[];
}

export interface AdvertisementPaymentsFiles_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPayments";
  advertisingPaymentsFiles: AdvertisementPaymentsFiles_authorized_manager_advertising_advertisingPayments_advertisingPaymentsFiles;
}

export interface AdvertisementPaymentsFiles_authorized_manager_advertising {
  __typename: "ManagerAdvertisingQuery";
  advertisingPayments: AdvertisementPaymentsFiles_authorized_manager_advertising_advertisingPayments;
}

export interface AdvertisementPaymentsFiles_authorized_manager {
  __typename: "ManagerQuery";
  advertising: AdvertisementPaymentsFiles_authorized_manager_advertising;
}

export interface AdvertisementPaymentsFiles_authorized {
  __typename: "AuthorizedQuery";
  manager: AdvertisementPaymentsFiles_authorized_manager;
}

export interface AdvertisementPaymentsFiles {
  authorized: AdvertisementPaymentsFiles_authorized;
}

export interface AdvertisementPaymentsFilesVariables {
  filter: AdvertisementPaymentsFilesFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAdvertisingPaymentFromFile
// ====================================================

export interface UpdateAdvertisingPaymentFromFile_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPaymentsMutation";
  updateAdvertisingPaymentFromFile: boolean;
}

export interface UpdateAdvertisingPaymentFromFile_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  advertisingPayments: UpdateAdvertisingPaymentFromFile_authorized_manager_advertising_advertisingPayments;
}

export interface UpdateAdvertisingPaymentFromFile_authorized_manager {
  __typename: "ManagerMutation";
  advertising: UpdateAdvertisingPaymentFromFile_authorized_manager_advertising;
}

export interface UpdateAdvertisingPaymentFromFile_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdateAdvertisingPaymentFromFile_authorized_manager;
}

export interface UpdateAdvertisingPaymentFromFile {
  authorized: UpdateAdvertisingPaymentFromFile_authorized;
}

export interface UpdateAdvertisingPaymentFromFileVariables {
  filter: UpdateAdvertisingPaymentFromFileFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateAdvertisingPaymentsFromFile
// ====================================================

export interface GenerateAdvertisingPaymentsFromFile_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPaymentsMutation";
  generateAdvertisingPaymentsFromFile: boolean;
}

export interface GenerateAdvertisingPaymentsFromFile_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  advertisingPayments: GenerateAdvertisingPaymentsFromFile_authorized_manager_advertising_advertisingPayments;
}

export interface GenerateAdvertisingPaymentsFromFile_authorized_manager {
  __typename: "ManagerMutation";
  advertising: GenerateAdvertisingPaymentsFromFile_authorized_manager_advertising;
}

export interface GenerateAdvertisingPaymentsFromFile_authorized {
  __typename: "AuthorizedMutation";
  manager: GenerateAdvertisingPaymentsFromFile_authorized_manager;
}

export interface GenerateAdvertisingPaymentsFromFile {
  authorized: GenerateAdvertisingPaymentsFromFile_authorized;
}

export interface GenerateAdvertisingPaymentsFromFileVariables {
  fileId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdvertisingPaymentsFromFile
// ====================================================

export interface AdvertisingPaymentsFromFile_authorized_manager_advertising_advertisingPayments_advertisingPaymentsFromFile {
  __typename: "AdvertisingPaymentsFromFile";
  paymentId: number;
  fileLine: number;
  walletNumber: string | null;
  amountRequest: number | null;
  currencyName: string | null;
  paymentMethodName: string | null;
  comments: string | null;
  partnerId: number | null;
  siteId: number | null;
  documentLink: string | null;
  statusCode: AdvertisingErrorStatus[] | null;
}

export interface AdvertisingPaymentsFromFile_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPayments";
  advertisingPaymentsFromFile: AdvertisingPaymentsFromFile_authorized_manager_advertising_advertisingPayments_advertisingPaymentsFromFile[];
}

export interface AdvertisingPaymentsFromFile_authorized_manager_advertising {
  __typename: "ManagerAdvertisingQuery";
  advertisingPayments: AdvertisingPaymentsFromFile_authorized_manager_advertising_advertisingPayments;
}

export interface AdvertisingPaymentsFromFile_authorized_manager {
  __typename: "ManagerQuery";
  advertising: AdvertisingPaymentsFromFile_authorized_manager_advertising;
}

export interface AdvertisingPaymentsFromFile_authorized {
  __typename: "AuthorizedQuery";
  manager: AdvertisingPaymentsFromFile_authorized_manager;
}

export interface AdvertisingPaymentsFromFile {
  authorized: AdvertisingPaymentsFromFile_authorized;
}

export interface AdvertisingPaymentsFromFileVariables {
  fileId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddAdvertisementPayment
// ====================================================

export interface AddAdvertisementPayment_authorized_manager_advertising_advertisingPayments_addAdvertisingPayment {
  __typename: "AddAdvertisingPaymentResponse";
  id: number;
}

export interface AddAdvertisementPayment_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPaymentsMutation";
  addAdvertisingPayment: AddAdvertisementPayment_authorized_manager_advertising_advertisingPayments_addAdvertisingPayment;
}

export interface AddAdvertisementPayment_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  advertisingPayments: AddAdvertisementPayment_authorized_manager_advertising_advertisingPayments;
}

export interface AddAdvertisementPayment_authorized_manager {
  __typename: "ManagerMutation";
  advertising: AddAdvertisementPayment_authorized_manager_advertising;
}

export interface AddAdvertisementPayment_authorized {
  __typename: "AuthorizedMutation";
  manager: AddAdvertisementPayment_authorized_manager;
}

export interface AddAdvertisementPayment {
  authorized: AddAdvertisementPayment_authorized;
}

export interface AddAdvertisementPaymentVariables {
  payment: AdvertisementPaymentInput;
  planId?: number | null;
  paymentPlanId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAdvertisementPayment
// ====================================================

export interface UpdateAdvertisementPayment_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPaymentsMutation";
  updateAdvertisingPayment: boolean;
}

export interface UpdateAdvertisementPayment_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  advertisingPayments: UpdateAdvertisementPayment_authorized_manager_advertising_advertisingPayments;
}

export interface UpdateAdvertisementPayment_authorized_manager {
  __typename: "ManagerMutation";
  advertising: UpdateAdvertisementPayment_authorized_manager_advertising;
}

export interface UpdateAdvertisementPayment_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdateAdvertisementPayment_authorized_manager;
}

export interface UpdateAdvertisementPayment {
  authorized: UpdateAdvertisementPayment_authorized;
}

export interface UpdateAdvertisementPaymentVariables {
  advPayment: UpdateAdvertisementPaymentParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApplyStatus
// ====================================================

export interface ApplyStatus_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPaymentsMutation";
  applyAdvertisingStatus: boolean;
}

export interface ApplyStatus_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  advertisingPayments: ApplyStatus_authorized_manager_advertising_advertisingPayments;
}

export interface ApplyStatus_authorized_manager {
  __typename: "ManagerMutation";
  advertising: ApplyStatus_authorized_manager_advertising;
}

export interface ApplyStatus_authorized {
  __typename: "AuthorizedMutation";
  manager: ApplyStatus_authorized_manager;
}

export interface ApplyStatus {
  authorized: ApplyStatus_authorized;
}

export interface ApplyStatusVariables {
  statusId: AdvertisementStatus;
  paymentsIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdvertisementPaymentOrders
// ====================================================

export interface GetAdvertisementPaymentOrders_authorized_manager_advertising_advertisingPayments_payments_advertisementPaymentOrders {
  __typename: "AdvertisementPaymentOrderTableViewModel";
  id: number;
  whoAdded: string | null;
  whoAddedFullName: string | null;
  whoAddedPosition: string | null;
  dateOfCreation: string | null;
  paymentMethodName: string | null;
  walletNumber: string | null;
  amountRequest: number;
  paidAmount: number;
  currencyName: string | null;
  comments: string | null;
  partnerId: number | null;
  siteId: number | null;
  statusName: AdvertisementStatus;
  paymentDate: string | null;
  transactionId: string | null;
  transactionPaySysId: string | null;
  whoChecked: string | null;
  whoCheckedFullName: string | null;
  whoCheckedPosition: string | null;
  checkDate: string | null;
  dealType: DealType;
}

export interface GetAdvertisementPaymentOrders_authorized_manager_advertising_advertisingPayments_payments {
  __typename: "GetAdvertisementPaymentOrders";
  advertisementPaymentOrders: GetAdvertisementPaymentOrders_authorized_manager_advertising_advertisingPayments_payments_advertisementPaymentOrders[];
  rowsCount: number;
}

export interface GetAdvertisementPaymentOrders_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPayments";
  payments: GetAdvertisementPaymentOrders_authorized_manager_advertising_advertisingPayments_payments;
}

export interface GetAdvertisementPaymentOrders_authorized_manager_advertising {
  __typename: "ManagerAdvertisingQuery";
  advertisingPayments: GetAdvertisementPaymentOrders_authorized_manager_advertising_advertisingPayments;
}

export interface GetAdvertisementPaymentOrders_authorized_manager {
  __typename: "ManagerQuery";
  advertising: GetAdvertisementPaymentOrders_authorized_manager_advertising;
}

export interface GetAdvertisementPaymentOrders_authorized {
  __typename: "AuthorizedQuery";
  manager: GetAdvertisementPaymentOrders_authorized_manager;
}

export interface GetAdvertisementPaymentOrders {
  authorized: GetAdvertisementPaymentOrders_authorized;
}

export interface GetAdvertisementPaymentOrdersVariables {
  filter: GetAdvertisementPaymentOrdersFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdvertisementPayment
// ====================================================

export interface GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_logs {
  __typename: "AdvertisementPaymentOrdersLogs";
  transactionId: string | null;
  transactionPaySysId: string | null;
  statusName: string;
  updatedBy: string | null;
  comments: string | null;
  dateOfCreation: string;
}

export interface GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_payment_fields {
  __typename: "AccountPaymentSystemField";
  id: string;
  name: string;
  value: string | null;
}

export interface GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_payment {
  __typename: "AccountPaymentSystem";
  id: number;
  name: string;
  fields: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_payment_fields[] | null;
}

export interface GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_placements {
  __typename: "ContractPlacementDescription";
  type: PlacementType;
  typeName: string | null;
  sum: number;
  percentSum: boolean;
}

export interface GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_advertisingCompany {
  __typename: "AdvertisingCompany";
  id: number;
  name: string;
}

export interface GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment {
  __typename: "AdvertisementPayment";
  logs: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_logs[];
  id: number;
  partnerId: number;
  payment: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_payment;
  paidAmount: number | null;
  placements: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_placements[] | null;
  currencyId: number | null;
  comments: string | null;
  siteId: number | null;
  status: AdvertisementStatus;
  advertisingCompany: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_advertisingCompany | null;
  countryId: number | null;
  dealType: DealType;
  amountRequest: number;
  documentLinks: string[] | null;
}

export interface GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPayments";
  advertisingPayment: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment;
}

export interface GetAdvertisementPayment_authorized_manager_advertising {
  __typename: "ManagerAdvertisingQuery";
  advertisingPayments: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments;
}

export interface GetAdvertisementPayment_authorized_manager {
  __typename: "ManagerQuery";
  advertising: GetAdvertisementPayment_authorized_manager_advertising;
}

export interface GetAdvertisementPayment_authorized {
  __typename: "AuthorizedQuery";
  manager: GetAdvertisementPayment_authorized_manager;
}

export interface GetAdvertisementPayment {
  authorized: GetAdvertisementPayment_authorized;
}

export interface GetAdvertisementPaymentVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdvertisingCompanyByName
// ====================================================

export interface AdvertisingCompanyByName_authorized_manager_advertising_advertisingPayments_advertisingCompaniesByName {
  __typename: "AdvertisingCompany";
  id: number;
  name: string;
}

export interface AdvertisingCompanyByName_authorized_manager_advertising_advertisingPayments {
  __typename: "ManagerAdvertisingPayments";
  advertisingCompaniesByName: AdvertisingCompanyByName_authorized_manager_advertising_advertisingPayments_advertisingCompaniesByName[];
}

export interface AdvertisingCompanyByName_authorized_manager_advertising {
  __typename: "ManagerAdvertisingQuery";
  advertisingPayments: AdvertisingCompanyByName_authorized_manager_advertising_advertisingPayments;
}

export interface AdvertisingCompanyByName_authorized_manager {
  __typename: "ManagerQuery";
  advertising: AdvertisingCompanyByName_authorized_manager_advertising;
}

export interface AdvertisingCompanyByName_authorized {
  __typename: "AuthorizedQuery";
  manager: AdvertisingCompanyByName_authorized_manager;
}

export interface AdvertisingCompanyByName {
  authorized: AdvertisingCompanyByName_authorized;
}

export interface AdvertisingCompanyByNameVariables {
  partOfName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFinplan
// ====================================================

export interface CreateFinplan_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlansMutation";
  create: number;
}

export interface CreateFinplan_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  finantialPlans: CreateFinplan_authorized_manager_advertising_finantialPlans;
}

export interface CreateFinplan_authorized_manager {
  __typename: "ManagerMutation";
  advertising: CreateFinplan_authorized_manager_advertising;
}

export interface CreateFinplan_authorized {
  __typename: "AuthorizedMutation";
  manager: CreateFinplan_authorized_manager;
}

export interface CreateFinplan {
  authorized: CreateFinplan_authorized;
}

export interface CreateFinplanVariables {
  partnerId: number;
  contract: FinantialPlanContract;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateAdversitingPaymentsPlans
// ====================================================

export interface GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans {
  __typename: "AdvertisingPaymentsPlan";
  id: string;
  order: number;
  type: AdvertisingPaymentType;
  start: string;
  end: string;
  amount: number;
  currencyName: string;
  paymentDateTime: string;
  advPaymentId: number | null;
  advPaymentAmount: number | null;
  advPaymentCurrencyName: string | null;
  advPaymentStatus: AdvertisementStatus | null;
}

export interface GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlansMutation";
  generateAdversitingPaymentsPlans: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans[];
}

export interface GenerateAdversitingPaymentsPlans_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  finantialPlans: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans;
}

export interface GenerateAdversitingPaymentsPlans_authorized_manager {
  __typename: "ManagerMutation";
  advertising: GenerateAdversitingPaymentsPlans_authorized_manager_advertising;
}

export interface GenerateAdversitingPaymentsPlans_authorized {
  __typename: "AuthorizedMutation";
  manager: GenerateAdversitingPaymentsPlans_authorized_manager;
}

export interface GenerateAdversitingPaymentsPlans {
  authorized: GenerateAdversitingPaymentsPlans_authorized;
}

export interface GenerateAdversitingPaymentsPlansVariables {
  description: PlansDescription;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFinplansContract
// ====================================================

export interface UpdateFinplansContract_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlansMutation";
  update: boolean | null;
}

export interface UpdateFinplansContract_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  finantialPlans: UpdateFinplansContract_authorized_manager_advertising_finantialPlans;
}

export interface UpdateFinplansContract_authorized_manager {
  __typename: "ManagerMutation";
  advertising: UpdateFinplansContract_authorized_manager_advertising;
}

export interface UpdateFinplansContract_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdateFinplansContract_authorized_manager;
}

export interface UpdateFinplansContract {
  authorized: UpdateFinplansContract_authorized;
}

export interface UpdateFinplansContractVariables {
  planId: number;
  contract: FinantialPlanContract;
  isCreation?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApplyFinPlanStatus
// ====================================================

export interface ApplyFinPlanStatus_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlansMutation";
  applyFinPlanStatus: boolean;
}

export interface ApplyFinPlanStatus_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  finantialPlans: ApplyFinPlanStatus_authorized_manager_advertising_finantialPlans;
}

export interface ApplyFinPlanStatus_authorized_manager {
  __typename: "ManagerMutation";
  advertising: ApplyFinPlanStatus_authorized_manager_advertising;
}

export interface ApplyFinPlanStatus_authorized {
  __typename: "AuthorizedMutation";
  manager: ApplyFinPlanStatus_authorized_manager;
}

export interface ApplyFinPlanStatus {
  authorized: ApplyFinPlanStatus_authorized;
}

export interface ApplyFinPlanStatusVariables {
  statusId: FinantialPlanStatus;
  planId?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPlan
// ====================================================

export interface GetPlan_authorized_manager_advertising_finantialPlans_plan_logs {
  __typename: "FinplanLogs";
  statusName: string;
  updatedBy: string | null;
  dateOfChange: string;
}

export interface GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_paymentMethod_fields {
  __typename: "AccountPaymentSystemField";
  id: string;
  name: string;
  value: string | null;
}

export interface GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_paymentMethod {
  __typename: "AccountPaymentSystem";
  id: number;
  name: string;
  fields: GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_paymentMethod_fields[] | null;
}

export interface GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_contractPlacements {
  __typename: "ContractPlacementDescription";
  type: PlacementType;
  typeName: string | null;
  sum: number;
  percentSum: boolean;
}

export interface GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_company {
  __typename: "AdvertisingCompany";
  id: number;
  name: string;
}

export interface GetPlan_authorized_manager_advertising_finantialPlans_plan_contract {
  __typename: "FinantialPlanContractDescription";
  contractNumber: string;
  contractName: string;
  dateStart: string;
  dateEnd: string;
  countryId: number | null;
  contractCountryId: number | null;
  paymentMethod: GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_paymentMethod | null;
  contactFullName: string | null;
  phone: string | null;
  email: string | null;
  advCurrencyId: number;
  contractSum: number;
  contractPlacements: GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_contractPlacements[];
  message: string | null;
  filePaths: string[] | null;
  company: GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_company | null;
}

export interface GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans_logs {
  __typename: "PaymentsPlanLogs";
  order: number;
  updatedBy: string | null;
  reason: string | null;
  dateOfChange: string;
}

export interface GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans {
  __typename: "AdvertisingPaymentsPlan";
  id: string;
  order: number;
  type: AdvertisingPaymentType;
  start: string;
  end: string;
  amount: number;
  currencyName: string;
  paymentDateTime: string;
  advPaymentId: number | null;
  advPaymentAmount: number | null;
  advPaymentCurrencyName: string | null;
  advPaymentStatus: AdvertisementStatus | null;
  logs: GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans_logs[] | null;
}

export interface GetPlan_authorized_manager_advertising_finantialPlans_plan {
  __typename: "FinantialPlanFullDescription";
  id: number;
  status: FinantialPlanStatus;
  previousStatus: FinantialPlanStatus | null;
  partnerId: number;
  logs: GetPlan_authorized_manager_advertising_finantialPlans_plan_logs[] | null;
  contract: GetPlan_authorized_manager_advertising_finantialPlans_plan_contract;
  paymentPlans: GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans[] | null;
}

export interface GetPlan_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlans";
  plan: GetPlan_authorized_manager_advertising_finantialPlans_plan;
}

export interface GetPlan_authorized_manager_advertising {
  __typename: "ManagerAdvertisingQuery";
  finantialPlans: GetPlan_authorized_manager_advertising_finantialPlans;
}

export interface GetPlan_authorized_manager {
  __typename: "ManagerQuery";
  advertising: GetPlan_authorized_manager_advertising;
}

export interface GetPlan_authorized {
  __typename: "AuthorizedQuery";
  manager: GetPlan_authorized_manager;
}

export interface GetPlan {
  authorized: GetPlan_authorized;
}

export interface GetPlanVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectPaymentPlanToPayment
// ====================================================

export interface ConnectPaymentPlanToPayment_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlansMutation";
  connectPaymentPlanToPayment: boolean | null;
}

export interface ConnectPaymentPlanToPayment_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  finantialPlans: ConnectPaymentPlanToPayment_authorized_manager_advertising_finantialPlans;
}

export interface ConnectPaymentPlanToPayment_authorized_manager {
  __typename: "ManagerMutation";
  advertising: ConnectPaymentPlanToPayment_authorized_manager_advertising;
}

export interface ConnectPaymentPlanToPayment_authorized {
  __typename: "AuthorizedMutation";
  manager: ConnectPaymentPlanToPayment_authorized_manager;
}

export interface ConnectPaymentPlanToPayment {
  authorized: ConnectPaymentPlanToPayment_authorized;
}

export interface ConnectPaymentPlanToPaymentVariables {
  planId: number;
  id: string;
  advPaymentId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAdvertisingPaymentPlan
// ====================================================

export interface CreateAdvertisingPaymentPlan_authorized_manager_advertising_finantialPlans_createAdversitingPaymentPlan {
  __typename: "AdversitingPaymentPlanResponse";
  id: string;
}

export interface CreateAdvertisingPaymentPlan_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlansMutation";
  createAdversitingPaymentPlan: CreateAdvertisingPaymentPlan_authorized_manager_advertising_finantialPlans_createAdversitingPaymentPlan;
}

export interface CreateAdvertisingPaymentPlan_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  finantialPlans: CreateAdvertisingPaymentPlan_authorized_manager_advertising_finantialPlans;
}

export interface CreateAdvertisingPaymentPlan_authorized_manager {
  __typename: "ManagerMutation";
  advertising: CreateAdvertisingPaymentPlan_authorized_manager_advertising;
}

export interface CreateAdvertisingPaymentPlan_authorized {
  __typename: "AuthorizedMutation";
  manager: CreateAdvertisingPaymentPlan_authorized_manager;
}

export interface CreateAdvertisingPaymentPlan {
  authorized: CreateAdvertisingPaymentPlan_authorized;
}

export interface CreateAdvertisingPaymentPlanVariables {
  planId: number;
  paymentPlan: AdversitingPaymentPlan;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFinPlan
// ====================================================

export interface DeleteFinPlan_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlansMutation";
  deleteFinPlan: boolean | null;
}

export interface DeleteFinPlan_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  finantialPlans: DeleteFinPlan_authorized_manager_advertising_finantialPlans;
}

export interface DeleteFinPlan_authorized_manager {
  __typename: "ManagerMutation";
  advertising: DeleteFinPlan_authorized_manager_advertising;
}

export interface DeleteFinPlan_authorized {
  __typename: "AuthorizedMutation";
  manager: DeleteFinPlan_authorized_manager;
}

export interface DeleteFinPlan {
  authorized: DeleteFinPlan_authorized;
}

export interface DeleteFinPlanVariables {
  planId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAdvertisingPaymentPlan
// ====================================================

export interface DeleteAdvertisingPaymentPlan_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlansMutation";
  deleteAdversitingPaymentPlan: boolean | null;
}

export interface DeleteAdvertisingPaymentPlan_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  finantialPlans: DeleteAdvertisingPaymentPlan_authorized_manager_advertising_finantialPlans;
}

export interface DeleteAdvertisingPaymentPlan_authorized_manager {
  __typename: "ManagerMutation";
  advertising: DeleteAdvertisingPaymentPlan_authorized_manager_advertising;
}

export interface DeleteAdvertisingPaymentPlan_authorized {
  __typename: "AuthorizedMutation";
  manager: DeleteAdvertisingPaymentPlan_authorized_manager;
}

export interface DeleteAdvertisingPaymentPlan {
  authorized: DeleteAdvertisingPaymentPlan_authorized;
}

export interface DeleteAdvertisingPaymentPlanVariables {
  planId: number;
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAdvertisingPaymentPlan
// ====================================================

export interface UpdateAdvertisingPaymentPlan_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlansMutation";
  updateAdversitingPaymentPlan: boolean | null;
}

export interface UpdateAdvertisingPaymentPlan_authorized_manager_advertising {
  __typename: "ManagerAdvertisingMutation";
  finantialPlans: UpdateAdvertisingPaymentPlan_authorized_manager_advertising_finantialPlans;
}

export interface UpdateAdvertisingPaymentPlan_authorized_manager {
  __typename: "ManagerMutation";
  advertising: UpdateAdvertisingPaymentPlan_authorized_manager_advertising;
}

export interface UpdateAdvertisingPaymentPlan_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdateAdvertisingPaymentPlan_authorized_manager;
}

export interface UpdateAdvertisingPaymentPlan {
  authorized: UpdateAdvertisingPaymentPlan_authorized;
}

export interface UpdateAdvertisingPaymentPlanVariables {
  planId: number;
  id: string;
  paymentPlan: AdversitingPaymentPlan;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FinantialPlans
// ====================================================

export interface FinantialPlans_authorized_manager_advertising_finantialPlans_plans_plansRows {
  __typename: "FinantialPlan";
  id: number;
  name: string;
  dateStart: string;
  dateEnd: string;
  currencyName: string;
  partnerId: number;
  createdBy: string | null;
  dateOfCreation: string;
  sumOfContract: number;
  paid: number;
  statusName: string;
  changedBy: string | null;
  dateOfChange: string | null;
  paymentPlanAmountSumm: number | null;
}

export interface FinantialPlans_authorized_manager_advertising_finantialPlans_plans {
  __typename: "FinantialPlansResponse";
  plansRows: FinantialPlans_authorized_manager_advertising_finantialPlans_plans_plansRows[];
  rowsCount: number;
}

export interface FinantialPlans_authorized_manager_advertising_finantialPlans {
  __typename: "ManagerFinantialPlans";
  plans: FinantialPlans_authorized_manager_advertising_finantialPlans_plans;
}

export interface FinantialPlans_authorized_manager_advertising {
  __typename: "ManagerAdvertisingQuery";
  finantialPlans: FinantialPlans_authorized_manager_advertising_finantialPlans;
}

export interface FinantialPlans_authorized_manager {
  __typename: "ManagerQuery";
  advertising: FinantialPlans_authorized_manager_advertising;
}

export interface FinantialPlans_authorized {
  __typename: "AuthorizedQuery";
  manager: FinantialPlans_authorized_manager;
}

export interface FinantialPlans {
  authorized: FinantialPlans_authorized;
}

export interface FinantialPlansVariables {
  filter: FinantialPlansFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPartner
// ====================================================

export interface AddPartner_authorized_manager_main {
  __typename: "ManagerMainMutation";
  addPartner: boolean;
}

export interface AddPartner_authorized_manager {
  __typename: "ManagerMutation";
  main: AddPartner_authorized_manager_main;
}

export interface AddPartner_authorized {
  __typename: "AuthorizedMutation";
  manager: AddPartner_authorized_manager;
}

export interface AddPartner {
  authorized: AddPartner_authorized;
}

export interface AddPartnerVariables {
  partner: NewPartner;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetManagerGraph
// ====================================================

export interface GetManagerGraph_authorized_manager_main_mainStatistic_graph_data {
  __typename: "QuickManagerGraphData";
  period: string;
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfRegistrations: number | null;
  countOfDirectLinks: number | null;
  countOfNewAccountsWithDeposits: number | null;
  comissionAnmount: number | null;
  profit: number | null;
  depositAmount: number | null;
  countOfDeposits: number | null;
}

export interface GetManagerGraph_authorized_manager_main_mainStatistic_graph {
  __typename: "QuickManagerGraph";
  status: ReportStatus;
  data: GetManagerGraph_authorized_manager_main_mainStatistic_graph_data[];
}

export interface GetManagerGraph_authorized_manager_main_mainStatistic {
  __typename: "MainManagerStatistic";
  graph: GetManagerGraph_authorized_manager_main_mainStatistic_graph;
}

export interface GetManagerGraph_authorized_manager_main {
  __typename: "ManagerMainQuery";
  mainStatistic: GetManagerGraph_authorized_manager_main_mainStatistic;
}

export interface GetManagerGraph_authorized_manager {
  __typename: "ManagerQuery";
  main: GetManagerGraph_authorized_manager_main;
}

export interface GetManagerGraph_authorized {
  __typename: "AuthorizedQuery";
  manager: GetManagerGraph_authorized_manager;
}

export interface GetManagerGraph {
  authorized: GetManagerGraph_authorized;
}

export interface GetManagerGraphVariables {
  currencyId: number;
  period: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ManagerQuickStatistic
// ====================================================

export interface ManagerQuickStatistic_authorized_manager_main_mainStatistic_quick_rows {
  __typename: "QuickManagerStatisticRow";
  currencyId: number;
  currencyName: string;
  countOfViews: number;
  countOfClicks: number;
  countOfRegistrations: number;
  countOfDirectLinks: number;
  profit: number;
  countOfNewDepositors: number;
  comissionRS: number;
  comissionCPA: number;
  comissionAmount: number;
}

export interface ManagerQuickStatistic_authorized_manager_main_mainStatistic_quick {
  __typename: "QuickManagerStatistic";
  status: ReportStatus;
  rows: ManagerQuickStatistic_authorized_manager_main_mainStatistic_quick_rows[];
}

export interface ManagerQuickStatistic_authorized_manager_main_mainStatistic {
  __typename: "MainManagerStatistic";
  quick: ManagerQuickStatistic_authorized_manager_main_mainStatistic_quick;
}

export interface ManagerQuickStatistic_authorized_manager_main {
  __typename: "ManagerMainQuery";
  mainStatistic: ManagerQuickStatistic_authorized_manager_main_mainStatistic;
}

export interface ManagerQuickStatistic_authorized_manager {
  __typename: "ManagerQuery";
  main: ManagerQuickStatistic_authorized_manager_main;
}

export interface ManagerQuickStatistic_authorized {
  __typename: "AuthorizedQuery";
  manager: ManagerQuickStatistic_authorized_manager;
}

export interface ManagerQuickStatistic {
  authorized: ManagerQuickStatistic_authorized;
}

export interface ManagerQuickStatisticVariables {
  period: ShortTimePeriods;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ManagerTopMarketingTools
// ====================================================

export interface ManagerTopMarketingTools_authorized_manager_main_mainStatistic_topMarketingTools_rows_marketingToolsType {
  __typename: "MarketingToolsType";
  id: string;
  name: string;
}

export interface ManagerTopMarketingTools_authorized_manager_main_mainStatistic_topMarketingTools_rows {
  __typename: "TopMarketingToolsStatisticRow";
  toolId: number;
  preview: string;
  marketingToolsType: ManagerTopMarketingTools_authorized_manager_main_mainStatistic_topMarketingTools_rows_marketingToolsType;
  countOfViews: number;
  countOfClicks: number;
  countOfDirectLinks: number;
  CTR: number;
  countOfRegistrations: number;
  profit: number;
  status: Status;
}

export interface ManagerTopMarketingTools_authorized_manager_main_mainStatistic_topMarketingTools {
  __typename: "TopMarketingToolsStatistic";
  status: ReportStatus;
  rows: ManagerTopMarketingTools_authorized_manager_main_mainStatistic_topMarketingTools_rows[];
}

export interface ManagerTopMarketingTools_authorized_manager_main_mainStatistic {
  __typename: "MainManagerStatistic";
  topMarketingTools: ManagerTopMarketingTools_authorized_manager_main_mainStatistic_topMarketingTools;
}

export interface ManagerTopMarketingTools_authorized_manager_main {
  __typename: "ManagerMainQuery";
  mainStatistic: ManagerTopMarketingTools_authorized_manager_main_mainStatistic;
}

export interface ManagerTopMarketingTools_authorized_manager {
  __typename: "ManagerQuery";
  main: ManagerTopMarketingTools_authorized_manager_main;
}

export interface ManagerTopMarketingTools_authorized {
  __typename: "AuthorizedQuery";
  manager: ManagerTopMarketingTools_authorized_manager;
}

export interface ManagerTopMarketingTools {
  authorized: ManagerTopMarketingTools_authorized;
}

export interface ManagerTopMarketingToolsVariables {
  period: ShortTimePeriods;
  currencyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ManagerTopPartners
// ====================================================

export interface ManagerTopPartners_authorized_manager_main_mainStatistic_topPartners_rows {
  __typename: "TopPartnersStatisticRow";
  partnerId: number;
  countOfViews: number;
  countOfClicks: number;
  countOfRegistrations: number;
  countOfDirectLinks: number;
  profit: number;
  countOfNewDepositors: number;
  depositAmount: number;
  comissionAmount: number;
}

export interface ManagerTopPartners_authorized_manager_main_mainStatistic_topPartners {
  __typename: "TopPartnersStatistic";
  status: ReportStatus;
  rows: ManagerTopPartners_authorized_manager_main_mainStatistic_topPartners_rows[];
}

export interface ManagerTopPartners_authorized_manager_main_mainStatistic {
  __typename: "MainManagerStatistic";
  topPartners: ManagerTopPartners_authorized_manager_main_mainStatistic_topPartners;
}

export interface ManagerTopPartners_authorized_manager_main {
  __typename: "ManagerMainQuery";
  mainStatistic: ManagerTopPartners_authorized_manager_main_mainStatistic;
}

export interface ManagerTopPartners_authorized_manager {
  __typename: "ManagerQuery";
  main: ManagerTopPartners_authorized_manager_main;
}

export interface ManagerTopPartners_authorized {
  __typename: "AuthorizedQuery";
  manager: ManagerTopPartners_authorized_manager;
}

export interface ManagerTopPartners {
  authorized: ManagerTopPartners_authorized;
}

export interface ManagerTopPartnersVariables {
  period: ShortTimePeriods;
  currencyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPartnerPassword
// ====================================================

export interface ResetPartnerPassword_authorized_manager_main {
  __typename: "ManagerMainMutation";
  resetPartnerPassword: boolean;
}

export interface ResetPartnerPassword_authorized_manager {
  __typename: "ManagerMutation";
  main: ResetPartnerPassword_authorized_manager_main;
}

export interface ResetPartnerPassword_authorized {
  __typename: "AuthorizedMutation";
  manager: ResetPartnerPassword_authorized_manager;
}

export interface ResetPartnerPassword {
  authorized: ResetPartnerPassword_authorized;
}

export interface ResetPartnerPasswordVariables {
  partnerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Reset2FA
// ====================================================

export interface Reset2FA_authorized_manager_main {
  __typename: "ManagerMainMutation";
  reset2FA: boolean;
}

export interface Reset2FA_authorized_manager {
  __typename: "ManagerMutation";
  main: Reset2FA_authorized_manager_main;
}

export interface Reset2FA_authorized {
  __typename: "AuthorizedMutation";
  manager: Reset2FA_authorized_manager;
}

export interface Reset2FA {
  authorized: Reset2FA_authorized;
}

export interface Reset2FAVariables {
  partnerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPartnersBonus
// ====================================================

export interface AddPartnersBonus_authorized_manager_main_addBonus {
  __typename: "AddedBonus";
  id: number | null;
}

export interface AddPartnersBonus_authorized_manager_main {
  __typename: "ManagerMainMutation";
  addBonus: AddPartnersBonus_authorized_manager_main_addBonus;
}

export interface AddPartnersBonus_authorized_manager {
  __typename: "ManagerMutation";
  main: AddPartnersBonus_authorized_manager_main;
}

export interface AddPartnersBonus_authorized {
  __typename: "AuthorizedMutation";
  manager: AddPartnersBonus_authorized_manager;
}

export interface AddPartnersBonus {
  authorized: AddPartnersBonus_authorized;
}

export interface AddPartnersBonusVariables {
  bonus: BonusData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerBonuses
// ====================================================

export interface PartnerBonuses_authorized_manager_main_partnerBonuses_bonuses {
  __typename: "PartnerBonusesData";
  id: string;
  currencyName: string | null;
  amount: number;
  period: string | null;
  description: string | null;
  whoAdded: string | null;
  date: string | null;
}

export interface PartnerBonuses_authorized_manager_main_partnerBonuses {
  __typename: "PartnerBonusesResponse";
  bonuses: PartnerBonuses_authorized_manager_main_partnerBonuses_bonuses[];
  rowsCount: number;
}

export interface PartnerBonuses_authorized_manager_main {
  __typename: "ManagerMainQuery";
  partnerBonuses: PartnerBonuses_authorized_manager_main_partnerBonuses;
}

export interface PartnerBonuses_authorized_manager {
  __typename: "ManagerQuery";
  main: PartnerBonuses_authorized_manager_main;
}

export interface PartnerBonuses_authorized {
  __typename: "AuthorizedQuery";
  manager: PartnerBonuses_authorized_manager;
}

export interface PartnerBonuses {
  authorized: PartnerBonuses_authorized;
}

export interface PartnerBonusesVariables {
  partnerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCommissionGroup
// ====================================================

export interface AddCommissionGroup_authorized_manager_main {
  __typename: "ManagerMainMutation";
  addCommissionGroup: boolean;
}

export interface AddCommissionGroup_authorized_manager {
  __typename: "ManagerMutation";
  main: AddCommissionGroup_authorized_manager_main;
}

export interface AddCommissionGroup_authorized {
  __typename: "AuthorizedMutation";
  manager: AddCommissionGroup_authorized_manager;
}

export interface AddCommissionGroup {
  authorized: AddCommissionGroup_authorized;
}

export interface AddCommissionGroupVariables {
  commissionGroup: CommissionGroupData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCommissionGroup
// ====================================================

export interface DeleteCommissionGroup_authorized_manager_main {
  __typename: "ManagerMainMutation";
  deleteCommissionGroup: boolean;
}

export interface DeleteCommissionGroup_authorized_manager {
  __typename: "ManagerMutation";
  main: DeleteCommissionGroup_authorized_manager_main;
}

export interface DeleteCommissionGroup_authorized {
  __typename: "AuthorizedMutation";
  manager: DeleteCommissionGroup_authorized_manager;
}

export interface DeleteCommissionGroup {
  authorized: DeleteCommissionGroup_authorized;
}

export interface DeleteCommissionGroupVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCommissionGroup
// ====================================================

export interface UpdateCommissionGroup_authorized_manager_main {
  __typename: "ManagerMainMutation";
  updateCommissionGroup: boolean;
}

export interface UpdateCommissionGroup_authorized_manager {
  __typename: "ManagerMutation";
  main: UpdateCommissionGroup_authorized_manager_main;
}

export interface UpdateCommissionGroup_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdateCommissionGroup_authorized_manager;
}

export interface UpdateCommissionGroup {
  authorized: UpdateCommissionGroup_authorized;
}

export interface UpdateCommissionGroupVariables {
  newCommissionGroup: NewCommissionGroupData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerCommissionGroups
// ====================================================

export interface PartnerCommissionGroups_authorized_manager_main_partnerCommissionGroups_commissionGroups {
  __typename: "PartnerCommissionGroups";
  id: number;
  commissionGroupId: number;
  currencyName: string | null;
  commissionGroupName: string | null;
  siteName: string | null;
  dateStart: string | null;
  dateEnd: string | null;
  dateAdd: string | null;
  dateUpdate: string | null;
  status: PartnerStatus;
}

export interface PartnerCommissionGroups_authorized_manager_main_partnerCommissionGroups {
  __typename: "PartnerCommissionGroupsResponse";
  commissionGroups: PartnerCommissionGroups_authorized_manager_main_partnerCommissionGroups_commissionGroups[];
  rowsCount: number;
}

export interface PartnerCommissionGroups_authorized_manager_main {
  __typename: "ManagerMainQuery";
  partnerCommissionGroups: PartnerCommissionGroups_authorized_manager_main_partnerCommissionGroups;
}

export interface PartnerCommissionGroups_authorized_manager {
  __typename: "ManagerQuery";
  main: PartnerCommissionGroups_authorized_manager_main;
}

export interface PartnerCommissionGroups_authorized {
  __typename: "AuthorizedQuery";
  manager: PartnerCommissionGroups_authorized_manager;
}

export interface PartnerCommissionGroups {
  authorized: PartnerCommissionGroups_authorized;
}

export interface PartnerCommissionGroupsVariables {
  partnerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPartnerPartnerGroup
// ====================================================

export interface AddPartnerPartnerGroup_authorized_manager_main {
  __typename: "ManagerMainMutation";
  addPartnerPartnerGroup: boolean;
}

export interface AddPartnerPartnerGroup_authorized_manager {
  __typename: "ManagerMutation";
  main: AddPartnerPartnerGroup_authorized_manager_main;
}

export interface AddPartnerPartnerGroup_authorized {
  __typename: "AuthorizedMutation";
  manager: AddPartnerPartnerGroup_authorized_manager;
}

export interface AddPartnerPartnerGroup {
  authorized: AddPartnerPartnerGroup_authorized;
}

export interface AddPartnerPartnerGroupVariables {
  data: AddPartnerPartnerGroupData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePartnerPartnerGroup
// ====================================================

export interface DeletePartnerPartnerGroup_authorized_manager_main {
  __typename: "ManagerMainMutation";
  deletePartnerPartnerGroup: boolean;
}

export interface DeletePartnerPartnerGroup_authorized_manager {
  __typename: "ManagerMutation";
  main: DeletePartnerPartnerGroup_authorized_manager_main;
}

export interface DeletePartnerPartnerGroup_authorized {
  __typename: "AuthorizedMutation";
  manager: DeletePartnerPartnerGroup_authorized_manager;
}

export interface DeletePartnerPartnerGroup {
  authorized: DeletePartnerPartnerGroup_authorized;
}

export interface DeletePartnerPartnerGroupVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePartnerPartnerGroup
// ====================================================

export interface UpdatePartnerPartnerGroup_authorized_manager_main {
  __typename: "ManagerMainMutation";
  updatePartnerPartnerGroup: boolean;
}

export interface UpdatePartnerPartnerGroup_authorized_manager {
  __typename: "ManagerMutation";
  main: UpdatePartnerPartnerGroup_authorized_manager_main;
}

export interface UpdatePartnerPartnerGroup_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdatePartnerPartnerGroup_authorized_manager;
}

export interface UpdatePartnerPartnerGroup {
  authorized: UpdatePartnerPartnerGroup_authorized;
}

export interface UpdatePartnerPartnerGroupVariables {
  data: UpdatePartnerPartnerGroupData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerGroups
// ====================================================

export interface PartnerGroups_authorized_manager_main_partnerGroups_data {
  __typename: "PartnerPartnerGroup";
  id: number;
  partnerGroupId: number;
  groupName: string | null;
  currencyName: string | null;
  siteName: string | null;
  dateStart: string | null;
  dateEnd: string | null;
  whoAdded: string | null;
  whoUpdated: string | null;
  status: boolean | null;
}

export interface PartnerGroups_authorized_manager_main_partnerGroups {
  __typename: "PartnerPartnerGroupsResponse";
  data: PartnerGroups_authorized_manager_main_partnerGroups_data[];
  totalCount: number;
}

export interface PartnerGroups_authorized_manager_main {
  __typename: "ManagerMainQuery";
  partnerGroups: PartnerGroups_authorized_manager_main_partnerGroups | null;
}

export interface PartnerGroups_authorized_manager {
  __typename: "ManagerQuery";
  main: PartnerGroups_authorized_manager_main;
}

export interface PartnerGroups_authorized {
  __typename: "AuthorizedQuery";
  manager: PartnerGroups_authorized_manager;
}

export interface PartnerGroups {
  authorized: PartnerGroups_authorized;
}

export interface PartnerGroupsVariables {
  params: PartnerPartnerGroupsParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentFiles
// ====================================================

export interface PaymentFiles_authorized_manager_main_paymentFiles_files {
  __typename: "PaymentFilesData";
  fileId: number;
  fileName: string;
  fileLink: string;
  status: Status;
  updatedBy: string | null;
  updatedDate: string | null;
}

export interface PaymentFiles_authorized_manager_main_paymentFiles {
  __typename: "PaymentFilesResponse";
  files: PaymentFiles_authorized_manager_main_paymentFiles_files[];
  rowsCount: number;
}

export interface PaymentFiles_authorized_manager_main {
  __typename: "ManagerMainQuery";
  paymentFiles: PaymentFiles_authorized_manager_main_paymentFiles;
}

export interface PaymentFiles_authorized_manager {
  __typename: "ManagerQuery";
  main: PaymentFiles_authorized_manager_main;
}

export interface PaymentFiles_authorized {
  __typename: "AuthorizedQuery";
  manager: PaymentFiles_authorized_manager;
}

export interface PaymentFiles {
  authorized: PaymentFiles_authorized;
}

export interface PaymentFilesVariables {
  filter: PaymentFilesFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePaymentFile
// ====================================================

export interface UpdatePaymentFile_authorized_manager_main {
  __typename: "ManagerMainMutation";
  updatePaymentFile: boolean;
}

export interface UpdatePaymentFile_authorized_manager {
  __typename: "ManagerMutation";
  main: UpdatePaymentFile_authorized_manager_main;
}

export interface UpdatePaymentFile_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdatePaymentFile_authorized_manager;
}

export interface UpdatePaymentFile {
  authorized: UpdatePaymentFile_authorized;
}

export interface UpdatePaymentFileVariables {
  partnerId: number;
  fileId: number;
  data: UpdatePaymentFileData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePaymentMethod
// ====================================================

export interface UpdatePaymentMethod_authorized_manager_main {
  __typename: "ManagerMainMutation";
  updatePartnerPaymentMethod: boolean;
}

export interface UpdatePaymentMethod_authorized_manager {
  __typename: "ManagerMutation";
  main: UpdatePaymentMethod_authorized_manager_main;
}

export interface UpdatePaymentMethod_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdatePaymentMethod_authorized_manager;
}

export interface UpdatePaymentMethod {
  authorized: UpdatePaymentMethod_authorized;
}

export interface UpdatePaymentMethodVariables {
  partnerId: number;
  data: UpdatePartnerPaymentMethodData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetOptions
// ====================================================

export interface SetOptions_authorized_manager_main {
  __typename: "ManagerMainMutation";
  setOptions: boolean;
}

export interface SetOptions_authorized_manager {
  __typename: "ManagerMutation";
  main: SetOptions_authorized_manager_main;
}

export interface SetOptions_authorized {
  __typename: "AuthorizedMutation";
  manager: SetOptions_authorized_manager;
}

export interface SetOptions {
  authorized: SetOptions_authorized;
}

export interface SetOptionsVariables {
  id: number;
  data: SetOptionsData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePartner
// ====================================================

export interface UpdatePartner_authorized_manager_main {
  __typename: "ManagerMainMutation";
  updatePartner: boolean;
}

export interface UpdatePartner_authorized_manager {
  __typename: "ManagerMutation";
  main: UpdatePartner_authorized_manager_main;
}

export interface UpdatePartner_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdatePartner_authorized_manager;
}

export interface UpdatePartner {
  authorized: UpdatePartner_authorized;
}

export interface UpdatePartnerVariables {
  id: number;
  data: UpdatePartnerData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPartnersPromocode
// ====================================================

export interface AddPartnersPromocode_authorized_manager_main_addPromoCode {
  __typename: "AddedPromocode";
  id: number;
}

export interface AddPartnersPromocode_authorized_manager_main {
  __typename: "ManagerMainMutation";
  addPromoCode: AddPartnersPromocode_authorized_manager_main_addPromoCode;
}

export interface AddPartnersPromocode_authorized_manager {
  __typename: "ManagerMutation";
  main: AddPartnersPromocode_authorized_manager_main;
}

export interface AddPartnersPromocode_authorized {
  __typename: "AuthorizedMutation";
  manager: AddPartnersPromocode_authorized_manager;
}

export interface AddPartnersPromocode {
  authorized: AddPartnersPromocode_authorized;
}

export interface AddPartnersPromocodeVariables {
  promoCode: PromoCodeData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerPromocodes
// ====================================================

export interface PartnerPromocodes_authorized_manager_main_partnerPromocodes_promocodes {
  __typename: "PartnerPromocodes";
  id: number;
  siteName: string | null;
  currencyName: string | null;
  promoCode: string | null;
  btag: string | null;
}

export interface PartnerPromocodes_authorized_manager_main_partnerPromocodes {
  __typename: "PartnerPromocodesResponse";
  promocodes: PartnerPromocodes_authorized_manager_main_partnerPromocodes_promocodes[];
  rowsCount: number;
}

export interface PartnerPromocodes_authorized_manager_main {
  __typename: "ManagerMainQuery";
  partnerPromocodes: PartnerPromocodes_authorized_manager_main_partnerPromocodes;
}

export interface PartnerPromocodes_authorized_manager {
  __typename: "ManagerQuery";
  main: PartnerPromocodes_authorized_manager_main;
}

export interface PartnerPromocodes_authorized {
  __typename: "AuthorizedQuery";
  manager: PartnerPromocodes_authorized_manager;
}

export interface PartnerPromocodes {
  authorized: PartnerPromocodes_authorized;
}

export interface PartnerPromocodesVariables {
  filter: PromocodesFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddReferralDomain
// ====================================================

export interface AddReferralDomain_authorized_manager_main {
  __typename: "ManagerMainMutation";
  addReferralDomain: boolean;
}

export interface AddReferralDomain_authorized_manager {
  __typename: "ManagerMutation";
  main: AddReferralDomain_authorized_manager_main;
}

export interface AddReferralDomain_authorized {
  __typename: "AuthorizedMutation";
  manager: AddReferralDomain_authorized_manager;
}

export interface AddReferralDomain {
  authorized: AddReferralDomain_authorized;
}

export interface AddReferralDomainVariables {
  data: ReferralDomainData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateReferralDomain
// ====================================================

export interface UpdateReferralDomain_authorized_manager_main {
  __typename: "ManagerMainMutation";
  updateReferralDomain: boolean;
}

export interface UpdateReferralDomain_authorized_manager {
  __typename: "ManagerMutation";
  main: UpdateReferralDomain_authorized_manager_main;
}

export interface UpdateReferralDomain_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdateReferralDomain_authorized_manager;
}

export interface UpdateReferralDomain {
  authorized: UpdateReferralDomain_authorized;
}

export interface UpdateReferralDomainVariables {
  data: ReferralDomainData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteReferralDomain
// ====================================================

export interface DeleteReferralDomain_authorized_manager_main {
  __typename: "ManagerMainMutation";
  deleteReferralDomain: boolean;
}

export interface DeleteReferralDomain_authorized_manager {
  __typename: "ManagerMutation";
  main: DeleteReferralDomain_authorized_manager_main;
}

export interface DeleteReferralDomain_authorized {
  __typename: "AuthorizedMutation";
  manager: DeleteReferralDomain_authorized_manager;
}

export interface DeleteReferralDomain {
  authorized: DeleteReferralDomain_authorized;
}

export interface DeleteReferralDomainVariables {
  data: DeleteReferralDomainData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReferralDomains
// ====================================================

export interface ReferralDomains_authorized_manager_main_referralDomains_data {
  __typename: "ReferralDomain";
  id: number | null;
  currency: string | null;
  resource: string | null;
  hostName: string | null;
  autoUpdate: boolean | null;
  autoEmailSending: boolean | null;
}

export interface ReferralDomains_authorized_manager_main_referralDomains {
  __typename: "ReferralDomainsResponse";
  data: ReferralDomains_authorized_manager_main_referralDomains_data[] | null;
  totalCount: number;
}

export interface ReferralDomains_authorized_manager_main {
  __typename: "ManagerMainQuery";
  referralDomains: ReferralDomains_authorized_manager_main_referralDomains | null;
}

export interface ReferralDomains_authorized_manager {
  __typename: "ManagerQuery";
  main: ReferralDomains_authorized_manager_main;
}

export interface ReferralDomains_authorized {
  __typename: "AuthorizedQuery";
  manager: ReferralDomains_authorized_manager;
}

export interface ReferralDomains {
  authorized: ReferralDomains_authorized;
}

export interface ReferralDomainsVariables {
  params: ReferralDomainsParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Partner
// ====================================================

export interface Partner_authorized_manager_main_partner_messenger {
  __typename: "MessengerAgent";
  id: number;
  credentials: string | null;
}

export interface Partner_authorized_manager_main_partner_payment_fields {
  __typename: "AccountPaymentSystemField";
  id: string;
  name: string;
  value: string | null;
}

export interface Partner_authorized_manager_main_partner_payment {
  __typename: "AccountPaymentSystem";
  id: number;
  name: string;
  fields: Partner_authorized_manager_main_partner_payment_fields[] | null;
}

export interface Partner_authorized_manager_main_partner {
  __typename: "Partner";
  id: number | null;
  name: string | null;
  site: string | null;
  email: string | null;
  phone: string | null;
  messenger: Partner_authorized_manager_main_partner_messenger | null;
  registrationDate: string | null;
  registrationSource: number | null;
  players: string | null;
  status: PartnerStatus;
  countryId: number | null;
  languageId: number | null;
  currencies: number[] | null;
  partnerSiteId: number | null;
  partnerId: number | null;
  note: string | null;
  autoPayment: boolean;
  calcPeriodMonth: boolean;
  isPostback: boolean;
  isDomainApi: boolean;
  promoCodsLimit: number;
  is2FAEnabled: boolean;
  payment: Partner_authorized_manager_main_partner_payment | null;
}

export interface Partner_authorized_manager_main {
  __typename: "ManagerMainQuery";
  partner: Partner_authorized_manager_main_partner;
}

export interface Partner_authorized_manager {
  __typename: "ManagerQuery";
  main: Partner_authorized_manager_main;
}

export interface Partner_authorized {
  __typename: "AuthorizedQuery";
  manager: Partner_authorized_manager;
}

export interface Partner {
  authorized: Partner_authorized;
}

export interface PartnerVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerSitesList
// ====================================================

export interface PartnerSitesList_authorized_manager_main_sites {
  __typename: "PartnerSite";
  isVisible: boolean;
  id: number;
  name: string;
}

export interface PartnerSitesList_authorized_manager_main {
  __typename: "ManagerMainQuery";
  sites: PartnerSitesList_authorized_manager_main_sites[];
}

export interface PartnerSitesList_authorized_manager {
  __typename: "ManagerQuery";
  main: PartnerSitesList_authorized_manager_main;
}

export interface PartnerSitesList_authorized {
  __typename: "AuthorizedQuery";
  manager: PartnerSitesList_authorized_manager;
}

export interface PartnerSitesList {
  authorized: PartnerSitesList_authorized;
}

export interface PartnerSitesListVariables {
  partnerId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangePartnerSiteVisibility
// ====================================================

export interface ChangePartnerSiteVisibility_authorized_manager_main {
  __typename: "ManagerMainMutation";
  changePartnerSiteVisibility: boolean;
}

export interface ChangePartnerSiteVisibility_authorized_manager {
  __typename: "ManagerMutation";
  main: ChangePartnerSiteVisibility_authorized_manager_main;
}

export interface ChangePartnerSiteVisibility_authorized {
  __typename: "AuthorizedMutation";
  manager: ChangePartnerSiteVisibility_authorized_manager;
}

export interface ChangePartnerSiteVisibility {
  authorized: ChangePartnerSiteVisibility_authorized;
}

export interface ChangePartnerSiteVisibilityVariables {
  id: number;
  isVisible: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditPartnerSite
// ====================================================

export interface EditPartnerSite_authorized_manager_main {
  __typename: "ManagerMainMutation";
  editPartnerSite: boolean;
}

export interface EditPartnerSite_authorized_manager {
  __typename: "ManagerMutation";
  main: EditPartnerSite_authorized_manager_main;
}

export interface EditPartnerSite_authorized {
  __typename: "AuthorizedMutation";
  manager: EditPartnerSite_authorized_manager;
}

export interface EditPartnerSite {
  authorized: EditPartnerSite_authorized;
}

export interface EditPartnerSiteVariables {
  id: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPartnerSites
// ====================================================

export interface GetPartnerSites_authorized_manager_main_getPartnerSites_data {
  __typename: "PartnerSite";
  isVisible: boolean;
  id: number;
  name: string;
}

export interface GetPartnerSites_authorized_manager_main_getPartnerSites {
  __typename: "GetPartnerSitesResponse";
  data: GetPartnerSites_authorized_manager_main_getPartnerSites_data[];
  totalCount: number;
}

export interface GetPartnerSites_authorized_manager_main {
  __typename: "ManagerMainQuery";
  getPartnerSites: GetPartnerSites_authorized_manager_main_getPartnerSites;
}

export interface GetPartnerSites_authorized_manager {
  __typename: "ManagerQuery";
  main: GetPartnerSites_authorized_manager_main;
}

export interface GetPartnerSites_authorized {
  __typename: "AuthorizedQuery";
  manager: GetPartnerSites_authorized_manager;
}

export interface GetPartnerSites {
  authorized: GetPartnerSites_authorized;
}

export interface GetPartnerSitesVariables {
  query: GetPartnerSitesParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerList
// ====================================================

export interface PartnerList_authorized_manager_main_partnerList_partners {
  __typename: "PartnerListData";
  partnerId: number;
  partnerName: string;
  email: string;
  country: string | null;
  siteName: string | null;
  registrationDate: string;
  commissionGroupEndDate: string | null;
  partnersGroup: string;
  comissionGroup: string;
  subpurtnerOf: number | null;
  countOfAccountsWithDeposits: string | null;
  status: PartnerStatus;
}

export interface PartnerList_authorized_manager_main_partnerList {
  __typename: "PartnersListResponse";
  partners: PartnerList_authorized_manager_main_partnerList_partners[];
  rowsCount: number;
}

export interface PartnerList_authorized_manager_main {
  __typename: "ManagerMainQuery";
  partnerList: PartnerList_authorized_manager_main_partnerList;
}

export interface PartnerList_authorized_manager {
  __typename: "ManagerQuery";
  main: PartnerList_authorized_manager_main;
}

export interface PartnerList_authorized {
  __typename: "AuthorizedQuery";
  manager: PartnerList_authorized_manager;
}

export interface PartnerList {
  authorized: PartnerList_authorized;
}

export interface PartnerListVariables {
  filter: PartnerListFilter;
  type: PartnerListType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPartnerGroup
// ====================================================

export interface AddPartnerGroup_authorized_manager_main {
  __typename: "ManagerMainMutation";
  addPartnerGroup: boolean;
}

export interface AddPartnerGroup_authorized_manager {
  __typename: "ManagerMutation";
  main: AddPartnerGroup_authorized_manager_main;
}

export interface AddPartnerGroup_authorized {
  __typename: "AuthorizedMutation";
  manager: AddPartnerGroup_authorized_manager;
}

export interface AddPartnerGroup {
  authorized: AddPartnerGroup_authorized;
}

export interface AddPartnerGroupVariables {
  partnerGroup: NewPartnerGroup;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailablePartners
// ====================================================

export interface AvailablePartners_authorized_manager_main_availablePartners_partners {
  __typename: "PartnerResponse";
  name: string | null;
  id: number;
  registrationDate: string | null;
  site: string | null;
  status: PartnerStatus | null;
  email: string | null;
  country: string | null;
}

export interface AvailablePartners_authorized_manager_main_availablePartners {
  __typename: "PartnersResponse";
  rowsCount: number;
  partners: AvailablePartners_authorized_manager_main_availablePartners_partners[];
}

export interface AvailablePartners_authorized_manager_main {
  __typename: "ManagerMainQuery";
  availablePartners: AvailablePartners_authorized_manager_main_availablePartners;
}

export interface AvailablePartners_authorized_manager {
  __typename: "ManagerQuery";
  main: AvailablePartners_authorized_manager_main;
}

export interface AvailablePartners_authorized {
  __typename: "AuthorizedQuery";
  manager: AvailablePartners_authorized_manager;
}

export interface AvailablePartners {
  authorized: AvailablePartners_authorized;
}

export interface AvailablePartnersVariables {
  query: PartnersQueryParams;
  filter?: AvailablePartnersFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditPartnerGroup
// ====================================================

export interface EditPartnerGroup_authorized_manager_main {
  __typename: "ManagerMainMutation";
  editPartnerGroup: boolean;
}

export interface EditPartnerGroup_authorized_manager {
  __typename: "ManagerMutation";
  main: EditPartnerGroup_authorized_manager_main;
}

export interface EditPartnerGroup_authorized {
  __typename: "AuthorizedMutation";
  manager: EditPartnerGroup_authorized_manager;
}

export interface EditPartnerGroup {
  authorized: EditPartnerGroup_authorized;
}

export interface EditPartnerGroupVariables {
  data: EditPartnerGroupData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerGroup
// ====================================================

export interface PartnerGroup_authorized_manager_main_partnerGroup {
  __typename: "PartnersGroup";
  id: number;
  name: string;
  currencyId: number;
}

export interface PartnerGroup_authorized_manager_main {
  __typename: "ManagerMainQuery";
  partnerGroup: PartnerGroup_authorized_manager_main_partnerGroup;
}

export interface PartnerGroup_authorized_manager {
  __typename: "ManagerQuery";
  main: PartnerGroup_authorized_manager_main;
}

export interface PartnerGroup_authorized {
  __typename: "AuthorizedQuery";
  manager: PartnerGroup_authorized_manager;
}

export interface PartnerGroup {
  authorized: PartnerGroup_authorized;
}

export interface PartnerGroupVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupPartners
// ====================================================

export interface GroupPartners_authorized_manager_main_groupPartners_partners {
  __typename: "PartnerResponse";
  country: string | null;
  email: string | null;
  id: number;
  name: string | null;
  registrationDate: string | null;
  site: string | null;
  status: PartnerStatus | null;
}

export interface GroupPartners_authorized_manager_main_groupPartners {
  __typename: "PartnersResponse";
  rowsCount: number;
  partners: GroupPartners_authorized_manager_main_groupPartners_partners[];
}

export interface GroupPartners_authorized_manager_main {
  __typename: "ManagerMainQuery";
  groupPartners: GroupPartners_authorized_manager_main_groupPartners;
}

export interface GroupPartners_authorized_manager {
  __typename: "ManagerQuery";
  main: GroupPartners_authorized_manager_main;
}

export interface GroupPartners_authorized {
  __typename: "AuthorizedQuery";
  manager: GroupPartners_authorized_manager;
}

export interface GroupPartners {
  authorized: GroupPartners_authorized;
}

export interface GroupPartnersVariables {
  query: PartnersQueryParams;
  filter: GroupPartnersFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostbacks
// ====================================================

export interface GetPostbacks_authorized_manager_main_postbacks_data {
  __typename: "PostbackData";
  id: number | null;
  campaignId: number | null;
  partnerId: number | null;
  siteName: string | null;
  currencyName: string | null;
  url: string | null;
  typeName: string | null;
  description: string | null;
  statusName: string | null;
}

export interface GetPostbacks_authorized_manager_main_postbacks {
  __typename: "PostbacksResponse";
  data: GetPostbacks_authorized_manager_main_postbacks_data[];
  totalCount: number;
}

export interface GetPostbacks_authorized_manager_main {
  __typename: "ManagerMainQuery";
  postbacks: GetPostbacks_authorized_manager_main_postbacks | null;
}

export interface GetPostbacks_authorized_manager {
  __typename: "ManagerQuery";
  main: GetPostbacks_authorized_manager_main;
}

export interface GetPostbacks_authorized {
  __typename: "AuthorizedQuery";
  manager: GetPostbacks_authorized_manager;
}

export interface GetPostbacks {
  authorized: GetPostbacks_authorized;
}

export interface GetPostbacksVariables {
  filter: PostbacksFilter;
  params?: PostbacksParams | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPostback
// ====================================================

export interface GetPostback_authorized_manager_main_postback_campaigns {
  __typename: "PostbackOption";
  value: string | null;
  label: string | null;
}

export interface GetPostback_authorized_manager_main_postback_statuses {
  __typename: "PostbackOption";
  value: string | null;
  label: string | null;
}

export interface GetPostback_authorized_manager_main_postback_sites {
  __typename: "PostbackOption";
  value: string | null;
  label: string | null;
}

export interface GetPostback_authorized_manager_main_postback_typeRequests {
  __typename: "PostbackOption";
  value: string | null;
  label: string | null;
}

export interface GetPostback_authorized_manager_main_postback_postbackTypes {
  __typename: "PostbackOption";
  value: string | null;
  label: string | null;
}

export interface GetPostback_authorized_manager_main_postback_childGuids {
  __typename: "PostbackOption";
  value: string | null;
  label: string | null;
}

export interface GetPostback_authorized_manager_main_postback {
  __typename: "PostbackResponse";
  id: number | null;
  guid: string | null;
  partnerId: number | null;
  campaignId: number | null;
  campaigns: GetPostback_authorized_manager_main_postback_campaigns[];
  statusId: number | null;
  statuses: GetPostback_authorized_manager_main_postback_statuses[];
  siteId: number | null;
  sites: GetPostback_authorized_manager_main_postback_sites[];
  internalId: number | null;
  typeRequestId: number | null;
  typeRequests: GetPostback_authorized_manager_main_postback_typeRequests[];
  postbackTypeId: number | null;
  postbackTypes: GetPostback_authorized_manager_main_postback_postbackTypes[];
  variable: string | null;
  minSum: number | null;
  minSumBet: number | null;
  url: string | null;
  staticParameters: string | null;
  linkForTraffic: string | null;
  isClickIdPostbackParameter: boolean | null;
  clickIdPostbackParameter: string | null;
  isPartnerIdPostbackParameter: boolean | null;
  partnerIdPostbackParameter: string | null;
  isTransaction: boolean | null;
  transaction: string | null;
  isSiteIdPostbackParameter: boolean | null;
  siteIdPostbackParameter: string | null;
  isOtherPostbackParameter: boolean | null;
  otherPostbackParameter: string | null;
  isSumdepPostbackParameter: boolean | null;
  sumdepPostbackParameter: string | null;
  childGuid: string | null;
  childGuids: GetPostback_authorized_manager_main_postback_childGuids[];
  linkForPostback: string | null;
}

export interface GetPostback_authorized_manager_main {
  __typename: "ManagerMainQuery";
  postback: GetPostback_authorized_manager_main_postback;
}

export interface GetPostback_authorized_manager {
  __typename: "ManagerQuery";
  main: GetPostback_authorized_manager_main;
}

export interface GetPostback_authorized {
  __typename: "AuthorizedQuery";
  manager: GetPostback_authorized_manager;
}

export interface GetPostback {
  authorized: GetPostback_authorized;
}

export interface GetPostbackVariables {
  postbackId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ModeratePostback
// ====================================================

export interface ModeratePostback_authorized_manager_main {
  __typename: "ManagerMainMutation";
  moderatePostback: boolean;
}

export interface ModeratePostback_authorized_manager {
  __typename: "ManagerMutation";
  main: ModeratePostback_authorized_manager_main;
}

export interface ModeratePostback_authorized {
  __typename: "AuthorizedMutation";
  manager: ModeratePostback_authorized_manager;
}

export interface ModeratePostback {
  authorized: ModeratePostback_authorized;
}

export interface ModeratePostbackVariables {
  filter: ModeratePostbackFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Campaigns
// ====================================================

export interface Campaigns_authorized_manager_campaigns_campaigns {
  __typename: "CampaignListItem";
  id: number;
  name: string | null;
  dateStart: string | null;
  dateEnd: string | null;
  url: string | null;
  currencyId: number;
  status: CampaignStatus;
}

export interface Campaigns_authorized_manager_campaigns {
  __typename: "ManagerCampaignsQuery";
  campaigns: Campaigns_authorized_manager_campaigns_campaigns[];
}

export interface Campaigns_authorized_manager {
  __typename: "ManagerQuery";
  campaigns: Campaigns_authorized_manager_campaigns;
}

export interface Campaigns_authorized {
  __typename: "AuthorizedQuery";
  manager: Campaigns_authorized_manager;
}

export interface Campaigns {
  authorized: Campaigns_authorized;
}

export interface CampaignsVariables {
  filter?: CampaignsFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCampaignStatus
// ====================================================

export interface UpdateCampaignStatus_authorized_manager_campaigns {
  __typename: "ManagerCampaignsMutation";
  updateCampaignStatus: boolean;
}

export interface UpdateCampaignStatus_authorized_manager {
  __typename: "ManagerMutation";
  campaigns: UpdateCampaignStatus_authorized_manager_campaigns;
}

export interface UpdateCampaignStatus_authorized {
  __typename: "AuthorizedMutation";
  manager: UpdateCampaignStatus_authorized_manager;
}

export interface UpdateCampaignStatus {
  authorized: UpdateCampaignStatus_authorized;
}

export interface UpdateCampaignStatusVariables {
  ids: number[];
  status: CampaignStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApplyStatusInvoices
// ====================================================

export interface ApplyStatusInvoices_authorized_manager_payments {
  __typename: "ManagerPaymentsMutation";
  applyStatusInvoices: boolean;
}

export interface ApplyStatusInvoices_authorized_manager {
  __typename: "ManagerMutation";
  payments: ApplyStatusInvoices_authorized_manager_payments;
}

export interface ApplyStatusInvoices_authorized {
  __typename: "AuthorizedMutation";
  manager: ApplyStatusInvoices_authorized_manager;
}

export interface ApplyStatusInvoices {
  authorized: ApplyStatusInvoices_authorized;
}

export interface ApplyStatusInvoicesVariables {
  invoicesIds: number[];
  statusId: PartnerInvoiceStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerInvoices
// ====================================================

export interface PartnerInvoices_authorized_manager_payments_partnerInvoices_invoices {
  __typename: "PartnerInvoices";
  id: number;
  currencyName: string | null;
  invoiceStatus: PartnerInvoiceStatus | null;
  partnerId: number;
  userName: string | null;
  period: string;
  invoiceType: PartnerInvoiceType | null;
  summRequest: number;
  paymentName: string | null;
  accountNumber: string | null;
  transactionId: string | null;
  players: string | null;
  active: boolean;
}

export interface PartnerInvoices_authorized_manager_payments_partnerInvoices {
  __typename: "PartnerInvoicesResponse";
  invoices: PartnerInvoices_authorized_manager_payments_partnerInvoices_invoices[];
  rowsCount: number;
}

export interface PartnerInvoices_authorized_manager_payments {
  __typename: "ManagerPaymentsQuery";
  partnerInvoices: PartnerInvoices_authorized_manager_payments_partnerInvoices;
}

export interface PartnerInvoices_authorized_manager {
  __typename: "ManagerQuery";
  payments: PartnerInvoices_authorized_manager_payments;
}

export interface PartnerInvoices_authorized {
  __typename: "AuthorizedQuery";
  manager: PartnerInvoices_authorized_manager;
}

export interface PartnerInvoices {
  authorized: PartnerInvoices_authorized;
}

export interface PartnerInvoicesVariables {
  filter: PartnerInvoicesFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RegenerateInvoice
// ====================================================

export interface RegenerateInvoice_authorized_manager_payments {
  __typename: "ManagerPaymentsMutation";
  regenerateInvoice: boolean;
}

export interface RegenerateInvoice_authorized_manager {
  __typename: "ManagerMutation";
  payments: RegenerateInvoice_authorized_manager_payments;
}

export interface RegenerateInvoice_authorized {
  __typename: "AuthorizedMutation";
  manager: RegenerateInvoice_authorized_manager;
}

export interface RegenerateInvoice {
  authorized: RegenerateInvoice_authorized;
}

export interface RegenerateInvoiceVariables {
  invoiceId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentsHistory
// ====================================================

export interface PaymentsHistory_authorized_manager_payments_paymentsHistory_paymentHistory {
  __typename: "PaymentsHistory";
  currencyName: string | null;
  partnerId: number;
  userName: string | null;
  period: string | null;
  totalCommission: number | null;
  carryover: number | null;
  bonusAmount: number | null;
  profit: number | null;
  holdAmount: number | null;
  paid: number | null;
  paymentDate: string | null;
  availableToWithdraw: number | null;
}

export interface PaymentsHistory_authorized_manager_payments_paymentsHistory {
  __typename: "PaymentsHistoryResponse";
  paymentHistory: PaymentsHistory_authorized_manager_payments_paymentsHistory_paymentHistory[];
  rowsCount: number;
}

export interface PaymentsHistory_authorized_manager_payments {
  __typename: "ManagerPaymentsQuery";
  paymentsHistory: PaymentsHistory_authorized_manager_payments_paymentsHistory;
}

export interface PaymentsHistory_authorized_manager {
  __typename: "ManagerQuery";
  payments: PaymentsHistory_authorized_manager_payments;
}

export interface PaymentsHistory_authorized {
  __typename: "AuthorizedQuery";
  manager: PaymentsHistory_authorized_manager;
}

export interface PaymentsHistory {
  authorized: PaymentsHistory_authorized;
}

export interface PaymentsHistoryVariables {
  filter: PaymentsHistoryFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ManagerFullReport
// ====================================================

export interface ManagerFullReport_authorized_manager_reports_fullReport_rows {
  __typename: "ManagerFullProfitReportEntity";
  partnerId: number | null;
  siteId: number | null;
  siteName: string | null;
  countOfViews: number;
  countOfClicks: number;
  countOfDirectLinks: number;
  CTR: number;
  countOfRegistrations: number;
  countOfRegistrationsWithDeposits: number;
  countOfAccountsWithDeposits: number;
  newDepositsSum: number;
  depositAmount: number;
  countOfDeposits: number;
  countOfActivePlayers: number;
  averageProfitPlayers: number;
  bonusAmount: number;
  profit: number;
  CPA: number;
  comissionRS: number;
  comissionAmount: number;
  countOfNewAccountsWithDeposits: number;
  uniqueInstallations: number;
  registrationsToInstallationsRatio: number;
}

export interface ManagerFullReport_authorized_manager_reports_fullReport_total {
  __typename: "ManagerFullProfitReportEntity";
  partnerId: number | null;
  siteId: number | null;
  siteName: string | null;
  countOfViews: number;
  countOfClicks: number;
  countOfDirectLinks: number;
  CTR: number;
  countOfRegistrations: number;
  countOfRegistrationsWithDeposits: number;
  countOfAccountsWithDeposits: number;
  newDepositsSum: number;
  depositAmount: number;
  countOfDeposits: number;
  countOfActivePlayers: number;
  averageProfitPlayers: number;
  bonusAmount: number;
  profit: number;
  CPA: number;
  comissionRS: number;
  comissionAmount: number;
  countOfNewAccountsWithDeposits: number;
  uniqueInstallations: number;
  registrationsToInstallationsRatio: number;
}

export interface ManagerFullReport_authorized_manager_reports_fullReport {
  __typename: "ManagerFullProfitReportTotalEntity";
  status: ReportStatus;
  hash: string | null;
  rows: (ManagerFullReport_authorized_manager_reports_fullReport_rows | null)[] | null;
  total: ManagerFullReport_authorized_manager_reports_fullReport_total | null;
  pagesCount: number | null;
}

export interface ManagerFullReport_authorized_manager_reports {
  __typename: "ManagerReportsQuery";
  fullReport: ManagerFullReport_authorized_manager_reports_fullReport;
}

export interface ManagerFullReport_authorized_manager {
  __typename: "ManagerQuery";
  reports: ManagerFullReport_authorized_manager_reports;
}

export interface ManagerFullReport_authorized {
  __typename: "AuthorizedQuery";
  manager: ManagerFullReport_authorized_manager;
}

export interface ManagerFullReport {
  authorized: ManagerFullReport_authorized;
}

export interface ManagerFullReportVariables {
  filter: ManagerFullReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlayerActivityReport
// ====================================================

export interface PlayerActivityReport_authorized_manager_reports_playersActivityReport_items {
  __typename: "PlayerActivityReport";
  id: number;
  fio: string | null;
  countPays: number | null;
  sumPays: number | null;
  sumOut: number | null;
  idPaysMounth: number | null;
  code: string | null;
  countActiveDays: number | null;
  countSuspiciousDays: number | null;
  verifiStatId: number | null;
  comment: number | null;
  classNameId: number | null;
  dateReg: string | null;
  doubl: number | null;
  siteId: number | null;
  sumPaysUsd: number;
  sumOutUsd: number;
  isBad: boolean;
}

export interface PlayerActivityReport_authorized_manager_reports_playersActivityReport {
  __typename: "PlayersActivityReportResponse";
  rowsCount: number;
  items: PlayerActivityReport_authorized_manager_reports_playersActivityReport_items[];
}

export interface PlayerActivityReport_authorized_manager_reports {
  __typename: "ManagerReportsQuery";
  playersActivityReport: PlayerActivityReport_authorized_manager_reports_playersActivityReport;
}

export interface PlayerActivityReport_authorized_manager {
  __typename: "ManagerQuery";
  reports: PlayerActivityReport_authorized_manager_reports;
}

export interface PlayerActivityReport_authorized {
  __typename: "AuthorizedQuery";
  manager: PlayerActivityReport_authorized_manager;
}

export interface PlayerActivityReport {
  authorized: PlayerActivityReport_authorized;
}

export interface PlayerActivityReportVariables {
  filter: PlayersActivityReportFilter;
  query: PaginationQueryParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ManagerQuickReport
// ====================================================

export interface ManagerQuickReport_authorized_manager_reports_quickReport_total {
  __typename: "ManagerQuickReportEntity";
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfDirectLinks: number | null;
  CTR: number | null;
  countOfRegistrations: number | null;
  registrationsClicksRatio: number | null;
  countOfRegistrationsWithDeposits: number | null;
  registrationsWithDepositsRegistrationsRatio: number | null;
  newDepositsSum: number | null;
  newDepositors: number | null;
  countOfAccountsWithDeposits: number | null;
  depositAmount: number | null;
  profit: number | null;
  countOfDeposits: number | null;
  countOfActivePlayers: number | null;
  averageProfitPlayers: number | null;
  bonusAmount: number | null;
  comissionRS: number | null;
  CPA: number | null;
  referalComission: number | null;
  comissionAmount: number | null;
  paymentSystemFees: number | null;
}

export interface ManagerQuickReport_authorized_manager_reports_quickReport {
  __typename: "ManagerQuickReport";
  status: ReportStatus;
  total: ManagerQuickReport_authorized_manager_reports_quickReport_total | null;
}

export interface ManagerQuickReport_authorized_manager_reports {
  __typename: "ManagerReportsQuery";
  quickReport: ManagerQuickReport_authorized_manager_reports_quickReport;
}

export interface ManagerQuickReport_authorized_manager {
  __typename: "ManagerQuery";
  reports: ManagerQuickReport_authorized_manager_reports;
}

export interface ManagerQuickReport_authorized {
  __typename: "AuthorizedQuery";
  manager: ManagerQuickReport_authorized_manager;
}

export interface ManagerQuickReport {
  authorized: ManagerQuickReport_authorized;
}

export interface ManagerQuickReportVariables {
  filter: ManagerQuickReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPartnersUsers
// ====================================================

export interface GetPartnersUsers_authorized_manager_settings_partnersUsers_data {
  __typename: "PartnersUser";
  name: string;
  email: string;
}

export interface GetPartnersUsers_authorized_manager_settings_partnersUsers {
  __typename: "PartnersUsersResponse";
  data: GetPartnersUsers_authorized_manager_settings_partnersUsers_data[];
  totalCount: number;
}

export interface GetPartnersUsers_authorized_manager_settings {
  __typename: "ManagerSettingsQuery";
  partnersUsers: GetPartnersUsers_authorized_manager_settings_partnersUsers | null;
}

export interface GetPartnersUsers_authorized_manager {
  __typename: "ManagerQuery";
  settings: GetPartnersUsers_authorized_manager_settings;
}

export interface GetPartnersUsers_authorized {
  __typename: "AuthorizedQuery";
  manager: GetPartnersUsers_authorized_manager;
}

export interface GetPartnersUsers {
  authorized: GetPartnersUsers_authorized;
}

export interface GetPartnersUsersVariables {
  query: PaginationQueryParams;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerInformation
// ====================================================

export interface PartnerInformation_authorized_user {
  __typename: "User";
  affiliateId: string;
  materialGroupId: string;
  adAgentId: string;
  EmailConfirmed: boolean;
  roles: (string | null)[] | null;
}

export interface PartnerInformation_authorized_partnerAndManager_data_currencies {
  __typename: "PartnerAndManagerCurrency";
  id: string;
  name: string;
}

export interface PartnerInformation_authorized_partnerAndManager_data {
  __typename: "PartnerAndManagerDataType";
  currencies: PartnerInformation_authorized_partnerAndManager_data_currencies[];
}

export interface PartnerInformation_authorized_partnerAndManager {
  __typename: "PartnerAndManagerQuery";
  data: PartnerInformation_authorized_partnerAndManager_data;
}

export interface PartnerInformation_authorized {
  __typename: "AuthorizedQuery";
  user: PartnerInformation_authorized_user;
  partnerAndManager: PartnerInformation_authorized_partnerAndManager;
}

export interface PartnerInformation {
  authorized: PartnerInformation_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFreshAffilateLinkDomain
// ====================================================

export interface GetFreshAffilateLinkDomain_authorized_partner {
  __typename: "PartnerQuery";
  freshAffilateLinkDomain: string;
}

export interface GetFreshAffilateLinkDomain_authorized {
  __typename: "AuthorizedQuery";
  partner: GetFreshAffilateLinkDomain_authorized_partner;
}

export interface GetFreshAffilateLinkDomain {
  authorized: GetFreshAffilateLinkDomain_authorized;
}

export interface GetFreshAffilateLinkDomainVariables {
  domainInfo: DomainInfo;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCommissionList
// ====================================================

export interface GetCommissionList_authorized_partner_comissionStructures_description_RevenueShareDescription_levels_calculations {
  __typename: "LevelCalculation";
  type: CalculationType;
  value: number;
}

export interface GetCommissionList_authorized_partner_comissionStructures_description_RevenueShareDescription_levels {
  __typename: "RevenueLevel";
  order: number;
  comissionPercent: number;
  calculations: GetCommissionList_authorized_partner_comissionStructures_description_RevenueShareDescription_levels_calculations[] | null;
}

export interface GetCommissionList_authorized_partner_comissionStructures_description_RevenueShareDescription {
  __typename: "RevenueShareDescription";
  calculationType: CalculationType | null;
  comissionPersent: number | null;
  negativeComission: boolean;
  adminPersent: number | null;
  dateStart: string;
  dateEnd: string | null;
  currency: string | null;
  comissionPercentAboveLevels: number | null;
  levels: GetCommissionList_authorized_partner_comissionStructures_description_RevenueShareDescription_levels[] | null;
}

export interface GetCommissionList_authorized_partner_comissionStructures_description_CPADescription_parametres {
  __typename: "CpaParametr";
  parametrNumber: number;
  comissionType: CalculationType;
  amount: number;
}

export interface GetCommissionList_authorized_partner_comissionStructures_description_CPADescription_levels {
  __typename: "CpaLevel";
  order: number;
  paymentPlayer: number;
  playersAmount: number;
}

export interface GetCommissionList_authorized_partner_comissionStructures_description_CPADescription {
  __typename: "CPADescription";
  amount: number | null;
  daysOfHold: number;
  dateStart: string;
  dateEnd: string | null;
  comissionPaymentAboveLevels: number | null;
  currency: string | null;
  parametres: GetCommissionList_authorized_partner_comissionStructures_description_CPADescription_parametres[] | null;
  levels: GetCommissionList_authorized_partner_comissionStructures_description_CPADescription_levels[] | null;
}

export interface GetCommissionList_authorized_partner_comissionStructures_description_RefDescription_levels {
  __typename: "RefLevel";
  order: number;
  comissionPercent: number;
}

export interface GetCommissionList_authorized_partner_comissionStructures_description_RefDescription {
  __typename: "RefDescription";
  negativeComission: boolean;
  dateStart: string;
  dateEnd: string | null;
  calculationMethodId: CalculationType;
  levels: GetCommissionList_authorized_partner_comissionStructures_description_RefDescription_levels[] | null;
}

export type GetCommissionList_authorized_partner_comissionStructures_description = GetCommissionList_authorized_partner_comissionStructures_description_RevenueShareDescription | GetCommissionList_authorized_partner_comissionStructures_description_CPADescription | GetCommissionList_authorized_partner_comissionStructures_description_RefDescription;

export interface GetCommissionList_authorized_partner_comissionStructures {
  __typename: "ComissionStructure";
  currency: string | null;
  comissionGroupDateStart: string | null;
  comissionGroupDateEnd: string | null;
  comissionStructureType: string | null;
  comissionGroupName: string | null;
  description: GetCommissionList_authorized_partner_comissionStructures_description;
}

export interface GetCommissionList_authorized_partner {
  __typename: "PartnerQuery";
  comissionStructures: GetCommissionList_authorized_partner_comissionStructures[];
}

export interface GetCommissionList_authorized {
  __typename: "AuthorizedQuery";
  partner: GetCommissionList_authorized_partner;
}

export interface GetCommissionList {
  authorized: GetCommissionList_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFeedback
// ====================================================

export interface CreateFeedback_authorized_partner {
  __typename: "PartnerMutation";
  createFeedback: boolean;
}

export interface CreateFeedback_authorized {
  __typename: "AuthorizedMutation";
  partner: CreateFeedback_authorized_partner | null;
}

export interface CreateFeedback {
  authorized: CreateFeedback_authorized;
}

export interface CreateFeedbackVariables {
  message: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPartnerGraph
// ====================================================

export interface GetPartnerGraph_authorized_partner_mainStatistic_graph_data {
  __typename: "QuickPartnerGraphData";
  period: string;
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfRegistrations: number | null;
  countOfDirectLinks: number | null;
  countOfNewAccountsWithDeposits: number | null;
  comissionAnmount: number | null;
}

export interface GetPartnerGraph_authorized_partner_mainStatistic_graph {
  __typename: "QuickPartnerGraph";
  status: ReportStatus;
  data: GetPartnerGraph_authorized_partner_mainStatistic_graph_data[];
}

export interface GetPartnerGraph_authorized_partner_mainStatistic {
  __typename: "MainPartnerStatistic";
  graph: GetPartnerGraph_authorized_partner_mainStatistic_graph;
}

export interface GetPartnerGraph_authorized_partner {
  __typename: "PartnerQuery";
  mainStatistic: GetPartnerGraph_authorized_partner_mainStatistic;
}

export interface GetPartnerGraph_authorized {
  __typename: "AuthorizedQuery";
  partner: GetPartnerGraph_authorized_partner;
}

export interface GetPartnerGraph {
  authorized: GetPartnerGraph_authorized;
}

export interface GetPartnerGraphVariables {
  currencyId: number;
  period: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPartnerPaymentReport
// ====================================================

export interface GetPartnerPaymentReport_authorized_partner_mainStatistic_getPartnerPayment {
  __typename: "GetPartnerPaymentResponse";
  paymentAmount: number;
  yesterdayPaymentAmount: number;
  currentMonthPaymentAmount: number;
  monthPaymentAmount: number;
  withdrawalAmount: number;
  commissionSummHold: number;
}

export interface GetPartnerPaymentReport_authorized_partner_mainStatistic {
  __typename: "MainPartnerStatistic";
  getPartnerPayment: GetPartnerPaymentReport_authorized_partner_mainStatistic_getPartnerPayment | null;
}

export interface GetPartnerPaymentReport_authorized_partner {
  __typename: "PartnerQuery";
  mainStatistic: GetPartnerPaymentReport_authorized_partner_mainStatistic;
}

export interface GetPartnerPaymentReport_authorized {
  __typename: "AuthorizedQuery";
  partner: GetPartnerPaymentReport_authorized_partner;
}

export interface GetPartnerPaymentReport {
  authorized: GetPartnerPaymentReport_authorized;
}

export interface GetPartnerPaymentReportVariables {
  currencyId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerQuickStatistic
// ====================================================

export interface PartnerQuickStatistic_authorized_partner_mainStatistic_quick_rows {
  __typename: "QuickPartnerStatisticRow";
  currencyId: number;
  currencyName: string;
  countOfViews: number;
  countOfClicks: number;
  countOfRegistrations: number;
  countOfDirectLinks: number;
  profit: number | null;
  countOfNewDepositors: number;
  comissionRS: number | null;
  comissionCPA: number | null;
  comissionAmount: number | null;
}

export interface PartnerQuickStatistic_authorized_partner_mainStatistic_quick {
  __typename: "QuickPartnerStatistic";
  status: ReportStatus;
  rows: PartnerQuickStatistic_authorized_partner_mainStatistic_quick_rows[];
}

export interface PartnerQuickStatistic_authorized_partner_mainStatistic {
  __typename: "MainPartnerStatistic";
  quick: PartnerQuickStatistic_authorized_partner_mainStatistic_quick;
}

export interface PartnerQuickStatistic_authorized_partner {
  __typename: "PartnerQuery";
  mainStatistic: PartnerQuickStatistic_authorized_partner_mainStatistic;
}

export interface PartnerQuickStatistic_authorized {
  __typename: "AuthorizedQuery";
  partner: PartnerQuickStatistic_authorized_partner;
}

export interface PartnerQuickStatistic {
  authorized: PartnerQuickStatistic_authorized;
}

export interface PartnerQuickStatisticVariables {
  period: ShortTimePeriods;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetOrdersAndPayments
// ====================================================

export interface GetOrdersAndPayments_authorized_partner_ordersAndPayments_orders {
  __typename: "Order";
  currencyName: string;
  date: string;
  paymentAmount: number;
  profit: number;
  rest: number;
  status: OrderStatus;
}

export interface GetOrdersAndPayments_authorized_partner_ordersAndPayments_payments {
  __typename: "Payment";
  currencyName: string;
  periodFromMondey: string;
  totalComission: number;
  paid: number;
  bonusAmount: number;
  profit: number;
  carryover: number;
  paymentDate: string;
  availableToWithdraw: number;
  holdAmount: number;
}

export interface GetOrdersAndPayments_authorized_partner_ordersAndPayments {
  __typename: "OrdersAndPayments";
  orders: GetOrdersAndPayments_authorized_partner_ordersAndPayments_orders[] | null;
  payments: GetOrdersAndPayments_authorized_partner_ordersAndPayments_payments[] | null;
}

export interface GetOrdersAndPayments_authorized_partner {
  __typename: "PartnerQuery";
  ordersAndPayments: GetOrdersAndPayments_authorized_partner_ordersAndPayments;
}

export interface GetOrdersAndPayments_authorized {
  __typename: "AuthorizedQuery";
  partner: GetOrdersAndPayments_authorized_partner;
}

export interface GetOrdersAndPayments {
  authorized: GetOrdersAndPayments_authorized;
}

export interface GetOrdersAndPaymentsVariables {
  filter: OrdersAndPaymentsFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditPassword
// ====================================================

export interface EditPassword_authorized_partnerAccount_editPassword {
  __typename: "PartnerAcoountEditPasswordResponse";
  id: string | null;
  accessToken: string | null;
  accessExpiresIn: number | null;
  refreshToken: string | null;
  refreshExpiresIn: number | null;
}

export interface EditPassword_authorized_partnerAccount {
  __typename: "PartnerAccountMutation";
  editPassword: EditPassword_authorized_partnerAccount_editPassword;
}

export interface EditPassword_authorized {
  __typename: "AuthorizedMutation";
  partnerAccount: EditPassword_authorized_partnerAccount;
}

export interface EditPassword {
  authorized: EditPassword_authorized;
}

export interface EditPasswordVariables {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendEmailConfirmation
// ====================================================

export interface SendEmailConfirmation_authorized_partnerAccount {
  __typename: "PartnerAccountMutation";
  sendEmailConfirmation: number;
}

export interface SendEmailConfirmation_authorized {
  __typename: "AuthorizedMutation";
  partnerAccount: SendEmailConfirmation_authorized_partnerAccount;
}

export interface SendEmailConfirmation {
  authorized: SendEmailConfirmation_authorized;
}

export interface SendEmailConfirmationVariables {
  agreeReceiveMailing?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetInformationAboutManager
// ====================================================

export interface GetInformationAboutManager_authorized_partner_getInformationAboutManagerForPartner {
  __typename: "InformationAboutManager";
  url: string | null;
  email: string | null;
  phone: string | null;
  messengerName: string | null;
  messengerUserName: string | null;
}

export interface GetInformationAboutManager_authorized_partner {
  __typename: "PartnerQuery";
  getInformationAboutManagerForPartner: GetInformationAboutManager_authorized_partner_getInformationAboutManagerForPartner | null;
}

export interface GetInformationAboutManager_authorized {
  __typename: "AuthorizedQuery";
  partner: GetInformationAboutManager_authorized_partner;
}

export interface GetInformationAboutManager {
  authorized: GetInformationAboutManager_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditAccount
// ====================================================

export interface EditAccount_authorized_partnerAccount {
  __typename: "PartnerAccountMutation";
  editAccount: boolean | null;
}

export interface EditAccount_authorized {
  __typename: "AuthorizedMutation";
  partnerAccount: EditAccount_authorized_partnerAccount;
}

export interface EditAccount {
  authorized: EditAccount_authorized;
}

export interface EditAccountVariables {
  accountInfo: EditAccountInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubscriptions
// ====================================================

export interface GetSubscriptions_authorized_partnerAccount_getMailSubscriptions {
  __typename: "GetMailSubscriptionsResponse";
  id: number;
  isEnabled: boolean;
  title: string;
}

export interface GetSubscriptions_authorized_partnerAccount {
  __typename: "PartnerAccountQuery";
  getMailSubscriptions: GetSubscriptions_authorized_partnerAccount_getMailSubscriptions[] | null;
}

export interface GetSubscriptions_authorized {
  __typename: "AuthorizedQuery";
  partnerAccount: GetSubscriptions_authorized_partnerAccount;
}

export interface GetSubscriptions {
  authorized: GetSubscriptions_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MailSubscribe
// ====================================================

export interface MailSubscribe_authorized_partnerAccount {
  __typename: "PartnerAccountMutation";
  mailSubscribe: number | null;
}

export interface MailSubscribe_authorized {
  __typename: "AuthorizedMutation";
  partnerAccount: MailSubscribe_authorized_partnerAccount;
}

export interface MailSubscribe {
  authorized: MailSubscribe_authorized;
}

export interface MailSubscribeVariables {
  subscriptionIds: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TwoFASetupData
// ====================================================

export interface TwoFASetupData_authorized_partnerAccount_twoFA_setupData {
  __typename: "TwoFASetupData";
  outpauth: string;
}

export interface TwoFASetupData_authorized_partnerAccount_twoFA {
  __typename: "TwoFAQuery";
  setupData: TwoFASetupData_authorized_partnerAccount_twoFA_setupData;
}

export interface TwoFASetupData_authorized_partnerAccount {
  __typename: "PartnerAccountQuery";
  twoFA: TwoFASetupData_authorized_partnerAccount_twoFA;
}

export interface TwoFASetupData_authorized {
  __typename: "AuthorizedQuery";
  partnerAccount: TwoFASetupData_authorized_partnerAccount;
}

export interface TwoFASetupData {
  authorized: TwoFASetupData_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActivateTwoFA
// ====================================================

export interface ActivateTwoFA_authorized_partnerAccount_twoFA {
  __typename: "TwoFAMutation";
  activate: string[];
}

export interface ActivateTwoFA_authorized_partnerAccount {
  __typename: "PartnerAccountMutation";
  twoFA: ActivateTwoFA_authorized_partnerAccount_twoFA;
}

export interface ActivateTwoFA_authorized {
  __typename: "AuthorizedMutation";
  partnerAccount: ActivateTwoFA_authorized_partnerAccount;
}

export interface ActivateTwoFA {
  authorized: ActivateTwoFA_authorized;
}

export interface ActivateTwoFAVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAuthState
// ====================================================

export interface GetAuthState_authorized_partnerAccount_twoFA_state {
  __typename: "TwoFAState";
  enabled: boolean;
  enable2FAChanging: boolean;
}

export interface GetAuthState_authorized_partnerAccount_twoFA {
  __typename: "TwoFAQuery";
  state: GetAuthState_authorized_partnerAccount_twoFA_state;
}

export interface GetAuthState_authorized_partnerAccount {
  __typename: "PartnerAccountQuery";
  twoFA: GetAuthState_authorized_partnerAccount_twoFA;
}

export interface GetAuthState_authorized {
  __typename: "AuthorizedQuery";
  partnerAccount: GetAuthState_authorized_partnerAccount;
}

export interface GetAuthState {
  authorized: GetAuthState_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeactivateTwoFA
// ====================================================

export interface DeactivateTwoFA_authorized_partnerAccount_twoFA {
  __typename: "TwoFAMutation";
  deactivate: string;
}

export interface DeactivateTwoFA_authorized_partnerAccount {
  __typename: "PartnerAccountMutation";
  twoFA: DeactivateTwoFA_authorized_partnerAccount_twoFA;
}

export interface DeactivateTwoFA_authorized {
  __typename: "AuthorizedMutation";
  partnerAccount: DeactivateTwoFA_authorized_partnerAccount;
}

export interface DeactivateTwoFA {
  authorized: DeactivateTwoFA_authorized;
}

export interface DeactivateTwoFAVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerAccount
// ====================================================

export interface PartnerAccount_authorized_partnerAccount_mainAccountData_messenger {
  __typename: "TypeMessenger";
  id: number;
  login: string;
}

export interface PartnerAccount_authorized_partnerAccount_mainAccountData_paymentSystem_fields {
  __typename: "AccountPaymentSystemField";
  id: string;
  name: string;
  value: string | null;
}

export interface PartnerAccount_authorized_partnerAccount_mainAccountData_paymentSystem {
  __typename: "AccountPaymentSystem";
  id: number;
  name: string;
  fields: PartnerAccount_authorized_partnerAccount_mainAccountData_paymentSystem_fields[] | null;
}

export interface PartnerAccount_authorized_partnerAccount_mainAccountData {
  __typename: "PartnerAccount";
  id: number;
  login: string;
  email: string | null;
  emailConfirmed: boolean;
  firstname: string;
  surname: string;
  phone: string | null;
  messenger: PartnerAccount_authorized_partnerAccount_mainAccountData_messenger | null;
  countryId: number;
  languageId: number;
  paymentSystem: PartnerAccount_authorized_partnerAccount_mainAccountData_paymentSystem | null;
  showGetChangeDomainEmails: boolean;
  getChangeDomainEmails: boolean;
}

export interface PartnerAccount_authorized_partnerAccount {
  __typename: "PartnerAccountQuery";
  mainAccountData: PartnerAccount_authorized_partnerAccount_mainAccountData | null;
}

export interface PartnerAccount_authorized {
  __typename: "AuthorizedQuery";
  partnerAccount: PartnerAccount_authorized_partnerAccount;
}

export interface PartnerAccount {
  authorized: PartnerAccount_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendMail
// ====================================================

export interface SendMail_authorized_partner {
  __typename: "PartnerMutation";
  sendMail: boolean;
}

export interface SendMail_authorized {
  __typename: "AuthorizedMutation";
  partner: SendMail_authorized_partner | null;
}

export interface SendMail {
  authorized: SendMail_authorized;
}

export interface SendMailVariables {
  message: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddSite
// ====================================================

export interface AddSite_authorized_partner_addPartnerSite {
  __typename: "addPartnerSiteResponse";
  id: number;
}

export interface AddSite_authorized_partner {
  __typename: "PartnerMutation";
  addPartnerSite: AddSite_authorized_partner_addPartnerSite;
}

export interface AddSite_authorized {
  __typename: "AuthorizedMutation";
  partner: AddSite_authorized_partner | null;
}

export interface AddSite {
  authorized: AddSite_authorized;
}

export interface AddSiteVariables {
  site: SiteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: HideSite
// ====================================================

export interface HideSite_authorized_partner {
  __typename: "PartnerMutation";
  hidePartnerSite: boolean;
}

export interface HideSite_authorized {
  __typename: "AuthorizedMutation";
  partner: HideSite_authorized_partner | null;
}

export interface HideSite {
  authorized: HideSite_authorized;
}

export interface HideSiteVariables {
  id: number;
  hidden: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllActualDomainAPIResources
// ====================================================

export interface GetAllActualDomainAPIResources_authorized_partner_actualDomainAPIResources_token {
  __typename: "ResourceToken";
  token: string;
  expirationDate: string;
}

export interface GetAllActualDomainAPIResources_authorized_partner_actualDomainAPIResources {
  __typename: "ActualDomainAPIResource";
  resourceName: string | null;
  currencyId: number;
  currencyName: string;
  token: GetAllActualDomainAPIResources_authorized_partner_actualDomainAPIResources_token | null;
}

export interface GetAllActualDomainAPIResources_authorized_partner {
  __typename: "PartnerQuery";
  actualDomainAPIResources: GetAllActualDomainAPIResources_authorized_partner_actualDomainAPIResources[];
}

export interface GetAllActualDomainAPIResources_authorized {
  __typename: "AuthorizedQuery";
  partner: GetAllActualDomainAPIResources_authorized_partner;
}

export interface GetAllActualDomainAPIResources {
  authorized: GetAllActualDomainAPIResources_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetNewActualDomainAPIToken
// ====================================================

export interface GetNewActualDomainAPIToken_authorized_partner_tokenForActualDomainAPI_token {
  __typename: "ResourceToken";
  token: string;
  expirationDate: string;
}

export interface GetNewActualDomainAPIToken_authorized_partner_tokenForActualDomainAPI {
  __typename: "CreatedAPIToken";
  currencyId: number;
  token: GetNewActualDomainAPIToken_authorized_partner_tokenForActualDomainAPI_token;
}

export interface GetNewActualDomainAPIToken_authorized_partner {
  __typename: "PartnerMutation";
  tokenForActualDomainAPI: GetNewActualDomainAPIToken_authorized_partner_tokenForActualDomainAPI;
}

export interface GetNewActualDomainAPIToken_authorized {
  __typename: "AuthorizedMutation";
  partner: GetNewActualDomainAPIToken_authorized_partner | null;
}

export interface GetNewActualDomainAPIToken {
  authorized: GetNewActualDomainAPIToken_authorized;
}

export interface GetNewActualDomainAPITokenVariables {
  currencyId: number;
  expirationTimeDays: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMediaCode
// ====================================================

export interface GetMediaCode_authorized_partner {
  __typename: "PartnerQuery";
  getMediaCode: string;
}

export interface GetMediaCode_authorized {
  __typename: "AuthorizedQuery";
  partner: GetMediaCode_authorized_partner;
}

export interface GetMediaCode {
  authorized: GetMediaCode_authorized;
}

export interface GetMediaCodeVariables {
  mediaId: number;
  mediaType: string;
  siteId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMedia
// ====================================================

export interface GetMedia_authorized_partner_media_mediaType {
  __typename: "MediaType";
  id: string;
  name: string;
}

export interface GetMedia_authorized_partner_media {
  __typename: "Media";
  mediaId: number;
  preview: string;
  mediaName: string | null;
  mediaType: GetMedia_authorized_partner_media_mediaType;
  languageId: number;
  languageName: string;
  width: number;
  height: number;
  currencyId: string;
  currencyName: string;
  htmlCode: string;
}

export interface GetMedia_authorized_partner {
  __typename: "PartnerQuery";
  media: GetMedia_authorized_partner_media[];
}

export interface GetMedia_authorized {
  __typename: "AuthorizedQuery";
  partner: GetMedia_authorized_partner;
}

export interface GetMedia {
  authorized: GetMedia_authorized;
}

export interface GetMediaVariables {
  filter: MediaFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePartnerLink
// ====================================================

export interface CreatePartnerLink_authorized_partner_partnerLinksMutations_createPartnerLink {
  __typename: "CreatePartnerLinkResponse";
  id: number;
  siteId: number;
  siteName: string;
  link: string;
  landingPage: string | null;
  subId: string | null;
  currencyName: string;
  hidden: boolean;
  token: string | null;
}

export interface CreatePartnerLink_authorized_partner_partnerLinksMutations {
  __typename: "PartnerLinksMutations";
  createPartnerLink: CreatePartnerLink_authorized_partner_partnerLinksMutations_createPartnerLink;
}

export interface CreatePartnerLink_authorized_partner {
  __typename: "PartnerMutation";
  partnerLinksMutations: CreatePartnerLink_authorized_partner_partnerLinksMutations;
}

export interface CreatePartnerLink_authorized {
  __typename: "AuthorizedMutation";
  partner: CreatePartnerLink_authorized_partner | null;
}

export interface CreatePartnerLink {
  authorized: CreatePartnerLink_authorized;
}

export interface CreatePartnerLinkVariables {
  filter: CreatePartnerLinkFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLinks
// ====================================================

export interface GetLinks_authorized_partner_partnerLinks_getPartnerLinks {
  __typename: "GetPartnerLinksResponse";
  linkId: number | null;
  siteName: string | null;
  landingPage: string | null;
  subId: string | null;
  finishedLink: string | null;
  currencyName: string | null;
  hidden: boolean;
  siteId: number;
  campaignName: string | null;
}

export interface GetLinks_authorized_partner_partnerLinks {
  __typename: "PartnerLinksQuery";
  getPartnerLinks: GetLinks_authorized_partner_partnerLinks_getPartnerLinks[];
}

export interface GetLinks_authorized_partner {
  __typename: "PartnerQuery";
  partnerLinks: GetLinks_authorized_partner_partnerLinks;
}

export interface GetLinks_authorized {
  __typename: "AuthorizedQuery";
  partner: GetLinks_authorized_partner;
}

export interface GetLinks {
  authorized: GetLinks_authorized;
}

export interface GetLinksVariables {
  hidden: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PrepareForDownloadApk
// ====================================================

export interface PrepareForDownloadApk_authorized_partner_partnerLinksMutations_prepareForDownloadApk {
  __typename: "PrepareForDownloadApkResponse";
  status: PrepareForDownloadApkStatus | null;
}

export interface PrepareForDownloadApk_authorized_partner_partnerLinksMutations {
  __typename: "PartnerLinksMutations";
  prepareForDownloadApk: PrepareForDownloadApk_authorized_partner_partnerLinksMutations_prepareForDownloadApk;
}

export interface PrepareForDownloadApk_authorized_partner {
  __typename: "PartnerMutation";
  partnerLinksMutations: PrepareForDownloadApk_authorized_partner_partnerLinksMutations;
}

export interface PrepareForDownloadApk_authorized {
  __typename: "AuthorizedMutation";
  partner: PrepareForDownloadApk_authorized_partner | null;
}

export interface PrepareForDownloadApk {
  authorized: PrepareForDownloadApk_authorized;
}

export interface PrepareForDownloadApkVariables {
  filter: PrepareForDownloadApkFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleShowLink
// ====================================================

export interface ToggleShowLink_authorized_partner_partnerLinksMutations_hidePartnerLink {
  __typename: "HideLinkResponse";
  id: number;
}

export interface ToggleShowLink_authorized_partner_partnerLinksMutations {
  __typename: "PartnerLinksMutations";
  hidePartnerLink: ToggleShowLink_authorized_partner_partnerLinksMutations_hidePartnerLink;
}

export interface ToggleShowLink_authorized_partner {
  __typename: "PartnerMutation";
  partnerLinksMutations: ToggleShowLink_authorized_partner_partnerLinksMutations;
}

export interface ToggleShowLink_authorized {
  __typename: "AuthorizedMutation";
  partner: ToggleShowLink_authorized_partner | null;
}

export interface ToggleShowLink {
  authorized: ToggleShowLink_authorized;
}

export interface ToggleShowLinkVariables {
  id: number;
  hidden: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePostback
// ====================================================

export interface CreatePostback_authorized_partner_postback {
  __typename: "PostbackMutation";
  createPostback: number;
}

export interface CreatePostback_authorized_partner {
  __typename: "PartnerMutation";
  postback: CreatePostback_authorized_partner_postback;
}

export interface CreatePostback_authorized {
  __typename: "AuthorizedMutation";
  partner: CreatePostback_authorized_partner | null;
}

export interface CreatePostback {
  authorized: CreatePostback_authorized;
}

export interface CreatePostbackVariables {
  filter: PostbackChange;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePostback
// ====================================================

export interface DeletePostback_authorized_partner_postback {
  __typename: "PostbackMutation";
  deletePostback: boolean | null;
}

export interface DeletePostback_authorized_partner {
  __typename: "PartnerMutation";
  postback: DeletePostback_authorized_partner_postback;
}

export interface DeletePostback_authorized {
  __typename: "AuthorizedMutation";
  partner: DeletePostback_authorized_partner | null;
}

export interface DeletePostback {
  authorized: DeletePostback_authorized;
}

export interface DeletePostbackVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Postback
// ====================================================

export interface Postback_authorized_partner_postback_postback {
  __typename: "Postback";
  id: number;
  statusId: number;
  siteId: number;
  campaignId: number | null;
  typeRequest: number;
  postbackType: number;
  variableNameInPartnerSystem: string;
  minSum: string | null;
  minSumBet: string | null;
  url: string;
  staticParameters: string | null;
  isClickIdPostbackParameter: boolean;
  clickIdPostbackParameter: string | null;
  isPartnerIdPostbackParameter: boolean;
  partnerIdPostbackParameter: string | null;
  isTransactionPostbackParameter: boolean;
  transactionPostbackParameter: string | null;
  isSiteIdPostbackParameter: boolean;
  siteIdPostbackParameter: string | null;
  isOtherPostbackParameter: boolean;
  otherPostbackParameter: string | null;
  isSumdepPostbackParameter: boolean;
  sumdepPostbackParameter: string | null;
  childId: number | null;
  LFT: string;
  LFP: string;
  comment: string;
}

export interface Postback_authorized_partner_postback {
  __typename: "PostbackQuery";
  postback: Postback_authorized_partner_postback_postback;
}

export interface Postback_authorized_partner {
  __typename: "PartnerQuery";
  postback: Postback_authorized_partner_postback;
}

export interface Postback_authorized {
  __typename: "AuthorizedQuery";
  partner: Postback_authorized_partner;
}

export interface Postback {
  authorized: Postback_authorized;
}

export interface PostbackVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostbackLists
// ====================================================

export interface PostbackLists_authorized_partner_postback_postbackLists_methods {
  __typename: "PostbackMethod";
  id: number;
  name: string;
}

export interface PostbackLists_authorized_partner_postback_postbackLists_types {
  __typename: "PostbackType";
  id: number;
  name: string;
}

export interface PostbackLists_authorized_partner_postback_postbackLists_children {
  __typename: "ChildPostback";
  id: number;
  name: string;
}

export interface PostbackLists_authorized_partner_postback_postbackLists {
  __typename: "PostbackLists";
  methods: PostbackLists_authorized_partner_postback_postbackLists_methods[];
  types: PostbackLists_authorized_partner_postback_postbackLists_types[];
  children: PostbackLists_authorized_partner_postback_postbackLists_children[];
}

export interface PostbackLists_authorized_partner_postback {
  __typename: "PostbackQuery";
  postbackLists: PostbackLists_authorized_partner_postback_postbackLists;
}

export interface PostbackLists_authorized_partner {
  __typename: "PartnerQuery";
  postback: PostbackLists_authorized_partner_postback;
}

export interface PostbackLists_authorized {
  __typename: "AuthorizedQuery";
  partner: PostbackLists_authorized_partner;
}

export interface PostbackLists {
  authorized: PostbackLists_authorized;
}

export interface PostbackListsVariables {
  id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Postbacks
// ====================================================

export interface Postbacks_authorized_partner_postback_postbacks {
  __typename: "PostbackItem";
  id: number;
  siteName: string;
  url: string;
  typeName: string;
  statusName: string;
  comment: string | null;
}

export interface Postbacks_authorized_partner_postback {
  __typename: "PostbackQuery";
  postbacks: Postbacks_authorized_partner_postback_postbacks[];
}

export interface Postbacks_authorized_partner {
  __typename: "PartnerQuery";
  postback: Postbacks_authorized_partner_postback;
}

export interface Postbacks_authorized {
  __typename: "AuthorizedQuery";
  partner: Postbacks_authorized_partner;
}

export interface Postbacks {
  authorized: Postbacks_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePostback
// ====================================================

export interface UpdatePostback_authorized_partner_postback {
  __typename: "PostbackMutation";
  updatePostback: boolean | null;
}

export interface UpdatePostback_authorized_partner {
  __typename: "PartnerMutation";
  postback: UpdatePostback_authorized_partner_postback;
}

export interface UpdatePostback_authorized {
  __typename: "AuthorizedMutation";
  partner: UpdatePostback_authorized_partner | null;
}

export interface UpdatePostback {
  authorized: UpdatePostback_authorized;
}

export interface UpdatePostbackVariables {
  id: number;
  filter: PostbackChange;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPromocode
// ====================================================

export interface AddPromocode_authorized_partner_addPartnerPromoCode {
  __typename: "PartnerPromoCodeForCreationResponse";
  I: number;
  PC: string;
}

export interface AddPromocode_authorized_partner {
  __typename: "PartnerMutation";
  addPartnerPromoCode: AddPromocode_authorized_partner_addPartnerPromoCode;
}

export interface AddPromocode_authorized {
  __typename: "AuthorizedMutation";
  partner: AddPromocode_authorized_partner | null;
}

export interface AddPromocode {
  authorized: AddPromocode_authorized;
}

export interface AddPromocodeVariables {
  siteId: number;
  merchantId: number;
  campaignId: number;
  promoCode?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnersPromocodes
// ====================================================

export interface PartnersPromocodes_authorized_partner_promoCodes {
  __typename: "PromoCodesEnity";
  I: number;
  AAI: number;
  PI: number;
  PC: string;
  SI: number;
  S: string;
  MI: number;
  MCI: number;
  M: string;
  BT: string;
}

export interface PartnersPromocodes_authorized_partner {
  __typename: "PartnerQuery";
  promoCodes: PartnersPromocodes_authorized_partner_promoCodes[];
}

export interface PartnersPromocodes_authorized {
  __typename: "AuthorizedQuery";
  partner: PartnersPromocodes_authorized_partner;
}

export interface PartnersPromocodes {
  authorized: PartnersPromocodes_authorized;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerFullReport
// ====================================================

export interface PartnerFullReport_authorized_partner_reports_fullReport_rows {
  __typename: "FullProfitReportEntity";
  siteId: number | null;
  siteName: string | null;
  countOfViews: number;
  countOfClicks: number;
  countOfDirectLinks: number;
  CTR: number;
  countOfRegistrations: number;
  countOfRegistrationsWithDeposits: number;
  countOfAccountsWithDeposits: number;
  newDepositsSum: number;
  depositAmount: number;
  countOfDeposits: number;
  countOfActivePlayers: number;
  averageProfitPlayers: number;
  bonusAmount: number;
  profit: number;
  CPA: number;
  comissionAmount: number;
  comissionRS: number;
  countOfNewAccountsWithDeposits: number;
}

export interface PartnerFullReport_authorized_partner_reports_fullReport_total {
  __typename: "FullProfitReportEntity";
  siteId: number | null;
  siteName: string | null;
  countOfViews: number;
  countOfClicks: number;
  countOfDirectLinks: number;
  CTR: number;
  countOfRegistrations: number;
  countOfRegistrationsWithDeposits: number;
  countOfAccountsWithDeposits: number;
  newDepositsSum: number;
  depositAmount: number;
  countOfDeposits: number;
  countOfActivePlayers: number;
  averageProfitPlayers: number;
  bonusAmount: number;
  profit: number;
  CPA: number;
  comissionAmount: number;
  comissionRS: number;
  countOfNewAccountsWithDeposits: number;
}

export interface PartnerFullReport_authorized_partner_reports_fullReport {
  __typename: "FullProfitReportTotalEntity";
  status: ReportStatus;
  hash: string | null;
  rows: (PartnerFullReport_authorized_partner_reports_fullReport_rows | null)[] | null;
  total: PartnerFullReport_authorized_partner_reports_fullReport_total | null;
  pagesCount: number | null;
}

export interface PartnerFullReport_authorized_partner_reports {
  __typename: "PartnerReportsQuery";
  fullReport: PartnerFullReport_authorized_partner_reports_fullReport;
}

export interface PartnerFullReport_authorized_partner {
  __typename: "PartnerQuery";
  reports: PartnerFullReport_authorized_partner_reports;
}

export interface PartnerFullReport_authorized {
  __typename: "AuthorizedQuery";
  partner: PartnerFullReport_authorized_partner;
}

export interface PartnerFullReport {
  authorized: PartnerFullReport_authorized;
}

export interface PartnerFullReportVariables {
  filter: FullReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMarketingToolsReport
// ====================================================

export interface GetMarketingToolsReport_authorized_partner_reports_marketingToolsReport_rows {
  __typename: "MarketingToolsReportRow";
  marketingToolId: number;
  marketingToolName: string | null;
  subId: string | null;
  marketingToolTypeName: string | null;
  siteId: number | null;
  siteName: string | null;
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfDirectLinks: number | null;
  clickThroughRate: number | null;
  countOfRegistrations: number | null;
  registrationsClicksRatio: number | null;
  countOfAccountsWithDeposits: number | null;
  countOfRegistrationsWithDeposits: number | null;
  newDepositors: number | null;
  registrationsWithDepositsRegistrationsRatio: number | null;
  depositAmount: number | null;
  bonusAmount: number | null;
  companyProfit: number | null;
  comissionRS: number | null;
  CPA: number | null;
  comissionAmount: number | null;
  uniqueInstallations: number | null;
}

export interface GetMarketingToolsReport_authorized_partner_reports_marketingToolsReport_total {
  __typename: "MarketingToolsReportRow";
  marketingToolId: number;
  marketingToolName: string | null;
  subId: string | null;
  marketingToolTypeName: string | null;
  siteId: number | null;
  siteName: string | null;
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfDirectLinks: number | null;
  clickThroughRate: number | null;
  countOfRegistrations: number | null;
  registrationsClicksRatio: number | null;
  countOfAccountsWithDeposits: number | null;
  countOfRegistrationsWithDeposits: number | null;
  newDepositors: number | null;
  registrationsWithDepositsRegistrationsRatio: number | null;
  depositAmount: number | null;
  bonusAmount: number | null;
  companyProfit: number | null;
  comissionRS: number | null;
  CPA: number | null;
  comissionAmount: number | null;
  uniqueInstallations: number | null;
}

export interface GetMarketingToolsReport_authorized_partner_reports_marketingToolsReport {
  __typename: "MarketingToolsReport";
  status: ReportStatus;
  hash: string | null;
  countonpage: number | null;
  rows: GetMarketingToolsReport_authorized_partner_reports_marketingToolsReport_rows[] | null;
  total: GetMarketingToolsReport_authorized_partner_reports_marketingToolsReport_total | null;
}

export interface GetMarketingToolsReport_authorized_partner_reports {
  __typename: "PartnerReportsQuery";
  marketingToolsReport: GetMarketingToolsReport_authorized_partner_reports_marketingToolsReport;
}

export interface GetMarketingToolsReport_authorized_partner {
  __typename: "PartnerQuery";
  reports: GetMarketingToolsReport_authorized_partner_reports;
}

export interface GetMarketingToolsReport_authorized {
  __typename: "AuthorizedQuery";
  partner: GetMarketingToolsReport_authorized_partner;
}

export interface GetMarketingToolsReport {
  authorized: GetMarketingToolsReport_authorized;
}

export interface GetMarketingToolsReportVariables {
  filter: MarketingToolsReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPlayersReport
// ====================================================

export interface GetPlayersReport_authorized_partner_reports_playersReport_rows {
  __typename: "PlayersReportRow";
  siteId: number | null;
  siteName: string | null;
  mediaId: number | null;
  subId: string | null;
  playerId: number | null;
  registrationDate: string | null;
  country: string | null;
  depositAmount: number | null;
  betsAmount: number | null;
  bonusAmount: number | null;
  companyProfit: number | null;
  comissionRS: number | null;
  CPA: number | null;
  comissionAmount: number | null;
  holdTime: string | null;
  blocked: boolean | null;
  clickId: string | null;
}

export interface GetPlayersReport_authorized_partner_reports_playersReport_total {
  __typename: "PlayersReportRow";
  siteId: number | null;
  siteName: string | null;
  mediaId: number | null;
  subId: string | null;
  playerId: number | null;
  registrationDate: string | null;
  country: string | null;
  depositAmount: number | null;
  betsAmount: number | null;
  bonusAmount: number | null;
  companyProfit: number | null;
  comissionRS: number | null;
  CPA: number | null;
  comissionAmount: number | null;
  holdTime: string | null;
  blocked: boolean | null;
  clickId: string | null;
}

export interface GetPlayersReport_authorized_partner_reports_playersReport {
  __typename: "PlayersReport";
  status: ReportStatus;
  hash: string | null;
  pagesCount: number | null;
  rows: GetPlayersReport_authorized_partner_reports_playersReport_rows[] | null;
  total: GetPlayersReport_authorized_partner_reports_playersReport_total | null;
}

export interface GetPlayersReport_authorized_partner_reports {
  __typename: "PartnerReportsQuery";
  playersReport: GetPlayersReport_authorized_partner_reports_playersReport;
}

export interface GetPlayersReport_authorized_partner {
  __typename: "PartnerQuery";
  reports: GetPlayersReport_authorized_partner_reports;
}

export interface GetPlayersReport_authorized {
  __typename: "AuthorizedQuery";
  partner: GetPlayersReport_authorized_partner;
}

export interface GetPlayersReport {
  authorized: GetPlayersReport_authorized;
}

export interface GetPlayersReportVariables {
  filter: PlayersReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetQuickReport
// ====================================================

export interface GetQuickReport_authorized_partner_reports_quickReport_total {
  __typename: "QuickReportEntity";
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfDirectLinks: number | null;
  CTR: number | null;
  countOfRegistrations: number | null;
  registrationsClicksRatio: number | null;
  countOfRegistrationsWithDeposits: number | null;
  registrationsWithDepositsRegistrationsRatio: number | null;
  newDepositsSum: number | null;
  newDepositors: number | null;
  countOfAccountsWithDeposits: number | null;
  depositAmount: number | null;
  profit: number | null;
  countOfDeposits: number | null;
  countOfActivePlayers: number | null;
  averageProfitPlayers: number | null;
  bonusAmount: number | null;
  comissionRS: number | null;
  CPA: number | null;
  referalComission: number | null;
  comissionAmount: number | null;
}

export interface GetQuickReport_authorized_partner_reports_quickReport {
  __typename: "QuickReport";
  status: ReportStatus;
  total: GetQuickReport_authorized_partner_reports_quickReport_total | null;
}

export interface GetQuickReport_authorized_partner_reports {
  __typename: "PartnerReportsQuery";
  quickReport: GetQuickReport_authorized_partner_reports_quickReport;
}

export interface GetQuickReport_authorized_partner {
  __typename: "PartnerQuery";
  reports: GetQuickReport_authorized_partner_reports;
}

export interface GetQuickReport_authorized {
  __typename: "AuthorizedQuery";
  partner: GetQuickReport_authorized_partner;
}

export interface GetQuickReport {
  authorized: GetQuickReport_authorized;
}

export interface GetQuickReportVariables {
  filter: QuickReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubpartnerReport
// ====================================================

export interface GetSubpartnerReport_authorized_partner_reports_subpartnerReport_rows {
  __typename: "SubpartnerReportRow";
  subPartnerId: number | null;
  siteName: string | null;
  supPartnerLvl: number | null;
  siteId: number | null;
  percentOfComissionOfLvl: number | null;
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfDirectLinks: number | null;
  countOfRegistrations: number | null;
  countOfNewAccountsWithDeposits: number | null;
  depositAmount: number | null;
  profit: number | null;
  CPA: number | null;
  referalComission: number | null;
  registrationDate: string | null;
}

export interface GetSubpartnerReport_authorized_partner_reports_subpartnerReport_total {
  __typename: "SubpartnerReportRow";
  subPartnerId: number | null;
  siteName: string | null;
  supPartnerLvl: number | null;
  siteId: number | null;
  percentOfComissionOfLvl: number | null;
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfDirectLinks: number | null;
  countOfRegistrations: number | null;
  countOfNewAccountsWithDeposits: number | null;
  depositAmount: number | null;
  profit: number | null;
  CPA: number | null;
  referalComission: number | null;
  registrationDate: string | null;
}

export interface GetSubpartnerReport_authorized_partner_reports_subpartnerReport {
  __typename: "SubpartnerReport";
  hash: string | null;
  status: ReportStatus;
  pagesCount: number | null;
  rows: GetSubpartnerReport_authorized_partner_reports_subpartnerReport_rows[] | null;
  total: GetSubpartnerReport_authorized_partner_reports_subpartnerReport_total | null;
}

export interface GetSubpartnerReport_authorized_partner_reports {
  __typename: "PartnerReportsQuery";
  subpartnerReport: GetSubpartnerReport_authorized_partner_reports_subpartnerReport;
}

export interface GetSubpartnerReport_authorized_partner {
  __typename: "PartnerQuery";
  reports: GetSubpartnerReport_authorized_partner_reports;
}

export interface GetSubpartnerReport_authorized {
  __typename: "AuthorizedQuery";
  partner: GetSubpartnerReport_authorized_partner;
}

export interface GetSubpartnerReport {
  authorized: GetSubpartnerReport_authorized;
}

export interface GetSubpartnerReportVariables {
  filter: SubpartnerReportFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: userFields
// ====================================================

export interface userFields {
  __typename: "User";
  id: string;
  name: string;
  csrf: string;
  affiliateId: string;
  roles: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: managerFullReportTableData
// ====================================================

export interface managerFullReportTableData {
  __typename: "ManagerFullProfitReportEntity";
  partnerId: number | null;
  siteId: number | null;
  siteName: string | null;
  countOfViews: number;
  countOfClicks: number;
  countOfDirectLinks: number;
  CTR: number;
  countOfRegistrations: number;
  countOfRegistrationsWithDeposits: number;
  countOfAccountsWithDeposits: number;
  newDepositsSum: number;
  depositAmount: number;
  countOfDeposits: number;
  countOfActivePlayers: number;
  averageProfitPlayers: number;
  bonusAmount: number;
  profit: number;
  CPA: number;
  comissionRS: number;
  comissionAmount: number;
  countOfNewAccountsWithDeposits: number;
  uniqueInstallations: number;
  registrationsToInstallationsRatio: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: orderEntity
// ====================================================

export interface orderEntity {
  __typename: "Order";
  currencyName: string;
  date: string;
  paymentAmount: number;
  profit: number;
  rest: number;
  status: OrderStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: paymentEntity
// ====================================================

export interface paymentEntity {
  __typename: "Payment";
  currencyName: string;
  periodFromMondey: string;
  totalComission: number;
  paid: number;
  bonusAmount: number;
  profit: number;
  carryover: number;
  paymentDate: string;
  availableToWithdraw: number;
  holdAmount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: fullReportTableData
// ====================================================

export interface fullReportTableData {
  __typename: "FullProfitReportEntity";
  siteId: number | null;
  siteName: string | null;
  countOfViews: number;
  countOfClicks: number;
  countOfDirectLinks: number;
  CTR: number;
  countOfRegistrations: number;
  countOfRegistrationsWithDeposits: number;
  countOfAccountsWithDeposits: number;
  newDepositsSum: number;
  depositAmount: number;
  countOfDeposits: number;
  countOfActivePlayers: number;
  averageProfitPlayers: number;
  bonusAmount: number;
  profit: number;
  CPA: number;
  comissionAmount: number;
  comissionRS: number;
  countOfNewAccountsWithDeposits: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: marketingTools
// ====================================================

export interface marketingTools {
  __typename: "MarketingToolsReportRow";
  marketingToolId: number;
  marketingToolName: string | null;
  subId: string | null;
  marketingToolTypeName: string | null;
  siteId: number | null;
  siteName: string | null;
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfDirectLinks: number | null;
  clickThroughRate: number | null;
  countOfRegistrations: number | null;
  registrationsClicksRatio: number | null;
  countOfAccountsWithDeposits: number | null;
  countOfRegistrationsWithDeposits: number | null;
  newDepositors: number | null;
  registrationsWithDepositsRegistrationsRatio: number | null;
  depositAmount: number | null;
  bonusAmount: number | null;
  companyProfit: number | null;
  comissionRS: number | null;
  CPA: number | null;
  comissionAmount: number | null;
  uniqueInstallations: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: playerRow
// ====================================================

export interface playerRow {
  __typename: "PlayersReportRow";
  siteId: number | null;
  siteName: string | null;
  mediaId: number | null;
  subId: string | null;
  playerId: number | null;
  registrationDate: string | null;
  country: string | null;
  depositAmount: number | null;
  betsAmount: number | null;
  bonusAmount: number | null;
  companyProfit: number | null;
  comissionRS: number | null;
  CPA: number | null;
  comissionAmount: number | null;
  holdTime: string | null;
  blocked: boolean | null;
  clickId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: quickReportEntity
// ====================================================

export interface quickReportEntity {
  __typename: "QuickReportEntity";
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfDirectLinks: number | null;
  CTR: number | null;
  countOfRegistrations: number | null;
  registrationsClicksRatio: number | null;
  countOfRegistrationsWithDeposits: number | null;
  registrationsWithDepositsRegistrationsRatio: number | null;
  newDepositsSum: number | null;
  newDepositors: number | null;
  countOfAccountsWithDeposits: number | null;
  depositAmount: number | null;
  profit: number | null;
  countOfDeposits: number | null;
  countOfActivePlayers: number | null;
  averageProfitPlayers: number | null;
  bonusAmount: number | null;
  comissionRS: number | null;
  CPA: number | null;
  referalComission: number | null;
  comissionAmount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: subpartnersRow
// ====================================================

export interface subpartnersRow {
  __typename: "SubpartnerReportRow";
  subPartnerId: number | null;
  siteName: string | null;
  supPartnerLvl: number | null;
  siteId: number | null;
  percentOfComissionOfLvl: number | null;
  countOfViews: number | null;
  countOfClicks: number | null;
  countOfDirectLinks: number | null;
  countOfRegistrations: number | null;
  countOfNewAccountsWithDeposits: number | null;
  depositAmount: number | null;
  profit: number | null;
  CPA: number | null;
  referalComission: number | null;
  registrationDate: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdvertisementStatus {
  APPROVED = "APPROVED",
  ARCHIVE = "ARCHIVE",
  CANCELED = "CANCELED",
  CREATED = "CREATED",
  DECLINED = "DECLINED",
  NEEDS_IMPROVEMENT = "NEEDS_IMPROVEMENT",
  NEW = "NEW",
  PARTIAL_PAYOUT = "PARTIAL_PAYOUT",
  SENT = "SENT",
  SUCCESS = "SUCCESS",
}

export enum AdvertisingErrorStatus {
  CURRENCY_NOT_FOUND = "CURRENCY_NOT_FOUND",
  CURRENCY_NOT_MATCH_PAYMENT_METHOD = "CURRENCY_NOT_MATCH_PAYMENT_METHOD",
  PARTNER_ID_INCORRECT = "PARTNER_ID_INCORRECT",
  PAYMENT_METHOD_NOT_FOUND = "PAYMENT_METHOD_NOT_FOUND",
  SITE_ID_INCORRECT = "SITE_ID_INCORRECT",
  WALLET_NUMBER_INCORRECT = "WALLET_NUMBER_INCORRECT",
}

export enum AdvertisingPaymentType {
  CORRECTION = "CORRECTION",
  MAIN = "MAIN",
}

export enum CalculationType {
  BETS_AMOUNT = "BETS_AMOUNT",
  COUNT_OF_BETS = "COUNT_OF_BETS",
  COUNT_OF_REGISTRATIONS = "COUNT_OF_REGISTRATIONS",
  COUNT_OF_REGISTRATIONS_WITH_DEPOSITS = "COUNT_OF_REGISTRATIONS_WITH_DEPOSITS",
  DEPOSIT_AMOUNT = "DEPOSIT_AMOUNT",
  NEW_DEPOSITS_SUM = "NEW_DEPOSITS_SUM",
  PROFIT = "PROFIT",
  UNKNOWN = "UNKNOWN",
}

export enum CampaignStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
}

export enum DealType {
  DIGITAL = "DIGITAL",
  OFFLINE = "OFFLINE",
}

export enum FinantialPlanStatus {
  APPROVED = "APPROVED",
  ARCHIVE = "ARCHIVE",
  CREATED = "CREATED",
  INTERMIDIATE = "INTERMIDIATE",
  PAID = "PAID",
  PAYING = "PAYING",
  REVISION_REQUIRED = "REVISION_REQUIRED",
}

export enum OrderStatus {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  ERROR = "ERROR",
  FOR_NEXT_PERIOD = "FOR_NEXT_PERIOD",
  PAID = "PAID",
  PENDING = "PENDING",
}

export enum PartnerInvoiceStatus {
  APPROVED = "APPROVED",
  PAID = "PAID",
  POSTPONED = "POSTPONED",
  PROCESSING = "PROCESSING",
  UNPAID = "UNPAID",
}

export enum PartnerInvoiceType {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
  NO_INVOICE = "NO_INVOICE",
}

export enum PartnerListType {
  AdvertisingPayments = "AdvertisingPayments",
  FinPlan = "FinPlan",
  PartnerList = "PartnerList",
}

export enum PartnerStatus {
  ACTIVE = "ACTIVE",
  DECLINED = "DECLINED",
  INACTIVE = "INACTIVE",
  ON_REVIEW = "ON_REVIEW",
}

export enum PaymentPeriod {
  OnesPer20Days = "OnesPer20Days",
  OnesPer3Months = "OnesPer3Months",
  OnesPer4Months = "OnesPer4Months",
  OnesPer6Months = "OnesPer6Months",
  OnesPerMonth = "OnesPerMonth",
  TwicePerMonth = "TwicePerMonth",
}

export enum PlacementType {
  BTL = "BTL",
  OOH = "OOH",
  OTHER = "OTHER",
  PRINT = "PRINT",
  RADIO = "RADIO",
  SPONSORSHIP = "SPONSORSHIP",
  TRANSPORT = "TRANSPORT",
  TV = "TV",
}

export enum PostbackStatus {
  ACTIVE = "ACTIVE",
  CREATED = "CREATED",
  DELETED = "DELETED",
  REJECTED = "REJECTED",
  REPEATED_MODERATION = "REPEATED_MODERATION",
}

export enum PrepareForDownloadApkStatus {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  TIME_LIMIT = "TIME_LIMIT",
}

export enum ReportStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export enum Rights {
  APPROVE_FIN_PLAN = "APPROVE_FIN_PLAN",
  CREATE_ADVERSITING_PAYMENT = "CREATE_ADVERSITING_PAYMENT",
  CREATE_FIN_PLAN = "CREATE_FIN_PLAN",
  EDIT_ADVERSITING_PAYMENT = "EDIT_ADVERSITING_PAYMENT",
}

export enum ShortTimePeriods {
  LAST_MONTH = "LAST_MONTH",
  THIS_MONTH = "THIS_MONTH",
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
}

export enum SortedAdvertisementPaymentOrdersColumn {
  amountRequest = "amountRequest",
  checkDate = "checkDate",
  comments = "comments",
  currencyName = "currencyName",
  dateOfCreation = "dateOfCreation",
  id = "id",
  paidAmount = "paidAmount",
  partnerId = "partnerId",
  paymentDate = "paymentDate",
  paymentMethodName = "paymentMethodName",
  siteId = "siteId",
  statusName = "statusName",
  transactionId = "transactionId",
  transactionPaySysId = "transactionPaySysId",
  walletNumber = "walletNumber",
  whoAdded = "whoAdded",
  whoAddedFullName = "whoAddedFullName",
  whoAddedPosition = "whoAddedPosition",
  whoChecked = "whoChecked",
  whoCheckedFullName = "whoCheckedFullName",
  whoCheckedPosition = "whoCheckedPosition",
}

export enum SortedManagerFullReportColumn {
  CPA = "CPA",
  CTR = "CTR",
  averageProfitPlayers = "averageProfitPlayers",
  bonusAmount = "bonusAmount",
  comissionAmount = "comissionAmount",
  comissionRS = "comissionRS",
  countOfAccountsWithDeposits = "countOfAccountsWithDeposits",
  countOfActivePlayers = "countOfActivePlayers",
  countOfClicks = "countOfClicks",
  countOfDeposits = "countOfDeposits",
  countOfDirectLinks = "countOfDirectLinks",
  countOfNewAccountsWithDeposits = "countOfNewAccountsWithDeposits",
  countOfRegistrations = "countOfRegistrations",
  countOfRegistrationsWithDeposits = "countOfRegistrationsWithDeposits",
  countOfViews = "countOfViews",
  depositAmount = "depositAmount",
  newDepositsSum = "newDepositsSum",
  partnerId = "partnerId",
  profit = "profit",
  registrationsToInstallationsRatio = "registrationsToInstallationsRatio",
  siteId = "siteId",
  siteName = "siteName",
  uniqueInstallations = "uniqueInstallations",
}

export enum SortedPartnerInvoicesColumn {
  accountNumber = "accountNumber",
  currencyName = "currencyName",
  id = "id",
  invoiceStatus = "invoiceStatus",
  invoiceType = "invoiceType",
  partnerId = "partnerId",
  paymentName = "paymentName",
  period = "period",
  players = "players",
  summRequest = "summRequest",
  transactionId = "transactionId",
  userName = "userName",
}

export enum SortedPartnerListColumn {
  comissionGroup = "comissionGroup",
  commissionGroupEndDate = "commissionGroupEndDate",
  countOfAccountsWithDeposits = "countOfAccountsWithDeposits",
  country = "country",
  email = "email",
  partnerId = "partnerId",
  partnerName = "partnerName",
  partnersGroup = "partnersGroup",
  registrationDate = "registrationDate",
  siteName = "siteName",
  status = "status",
  subpurtnerOf = "subpurtnerOf",
}

export enum SortedPaymentFilesColumn {
  fileId = "fileId",
  fileLink = "fileLink",
  fileName = "fileName",
  status = "status",
  updatedBy = "updatedBy",
  updatedDate = "updatedDate",
}

export enum SortedPaymentsHistoryColumn {
  availableToWithdraw = "availableToWithdraw",
  bonusAmount = "bonusAmount",
  carryover = "carryover",
  currencyName = "currencyName",
  holdAmount = "holdAmount",
  paid = "paid",
  partnerId = "partnerId",
  paymentDate = "paymentDate",
  period = "period",
  profit = "profit",
  totalCommission = "totalCommission",
  userName = "userName",
}

export enum SortedPostbacksColumn {
  campaignId = "campaignId",
  id = "id",
  partnerId = "partnerId",
  siteName = "siteName",
  statusName = "statusName",
  typeName = "typeName",
  url = "url",
}

export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface AccountPaymentFieldInput {
  id: number;
  value?: string | null;
  validationTemplate?: string | null;
  required?: boolean | null;
  name?: string | null;
}

export interface AccountPaymentInput {
  id: number;
  name: string;
  fields?: AccountPaymentFieldInput[] | null;
  currencyId?: (number | null)[] | null;
  code?: string | null;
  pattern?: string | null;
  rules?: string | null;
  isDisplay?: boolean | null;
}

export interface AddPartnerPartnerGroupData {
  partnerId: number;
  partnerGroupId: number;
  currencyId: number;
  endless: boolean;
  dateStart: string;
  dateEnd?: string | null;
  siteId?: number | null;
}

export interface AdversitingPaymentPlan {
  type: AdvertisingPaymentType;
  start: string;
  end: string;
  amount: number;
  paymentDateTime: string;
  reason?: string | null;
}

export interface AdvertisementPaymentInput {
  partnerId?: number | null;
  siteId?: number | null;
  currencyId: number;
  amountRequest: number;
  payment: PaymentSystemInput;
  placements?: ContractPlacement[] | null;
  comments?: string | null;
  documentLinks?: string[] | null;
}

export interface AdvertisementPaymentsFilesFilter {
  fileId?: number | null;
  fileName?: string | null;
  startPeriod: string;
  endPeriod: string;
}

export interface AvailablePartnersFilter {
  partnerId?: number | null;
  partnerGroupId?: number | null;
  currencyId?: number | null;
  countryId?: number | null;
  search?: string | null;
  showPartnersWithoutGroup?: boolean | null;
}

export interface BonusData {
  partnerId: number;
  currencyId: number;
  amount: number;
  description?: string | null;
}

export interface CampaignSelector {
  id?: number | null;
  name?: string | null;
}

export interface CampaignsFilter {
  id?: number | null;
  name?: string | null;
  currencyId?: number | null;
  languageId?: number | null;
  status?: CampaignStatus | null;
}

export interface CommissionGroupData {
  partnerId: number;
  commissionGroupId: number;
  currencyId: number;
  noLimitedGroup: boolean;
  dateStart: string;
  dateEnd: string;
  siteId?: number | null;
}

export interface ContractPlacement {
  type: PlacementType;
  typeName?: string | null;
  sum: number;
  percentSum: boolean;
}

export interface CreatePartnerLinkFilter {
  siteId: number;
  currencyId: number;
  campaignId: number;
  subId?: string | null;
  landingPage?: string | null;
}

export interface DeleteReferralDomainData {
  partnerId: number;
  currencyId: number;
}

export interface DomainInfo {
  affiliateId: string;
  materialGroupId: string;
  adAgentId: string;
  currency: string;
}

export interface EditAccountInput {
  countryId: number;
  languageId: number;
  getChangeDomainEmails: boolean;
}

export interface EditPartnerGroupData {
  id: number;
  currencyId: number;
  name: string;
  addedIds: number[];
  removedIds: number[];
}

export interface FinantialPlanContract {
  contractNumber: string;
  contractName: string;
  dateStart: string;
  dateEnd: string;
  contractCountryId?: number | null;
  countryId?: number | null;
  paymentMethod: RegistrationPaymentSystem;
  contactFullName?: string | null;
  phone?: string | null;
  email?: string | null;
  advCurrencyId: number;
  contractSum: number;
  contractPlacements: ContractPlacement[];
  message?: string | null;
  filePaths?: string[] | null;
  companyId?: number | null;
  status: FinantialPlanStatus;
}

export interface FinantialPlansFilter {
  startPeriod: string;
  endPeriod: string;
  planId?: number | null;
  partnerId?: number | null;
  planName?: string | null;
  currencyId?: number | null;
  status?: FinantialPlanStatus | null;
}

export interface FullReportFilter {
  merchant: number;
  startPeriod: string;
  endPeriod: string;
  siteId?: number | null;
  promoId?: string | null;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedType | null;
  registrationSourceId?: number | null;
  methood: string;
}

export interface GetAdvertisementPaymentOrdersFilter {
  whoCreated?: string | null;
  currencyId?: number | null;
  advertisementPaymentOrderStatusId?: AdvertisementStatus | null;
  startPeriod: string;
  endPeriod: string;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedAdvertisementPaymentOrdersType | null;
  search?: string | null;
  dealType?: DealType | null;
  fromFinPlan?: boolean | null;
}

export interface GetNewsListParams {
  culture: string;
  typeNews: string;
}

export interface GetNewsParams {
  culture: string;
  newsId: string;
}

export interface GetPartnerSitesParams {
  isVisible: boolean;
  partnerId: number;
  pageNumber?: number | null;
  countOnPage?: number | null;
  sorted?: SortedType | null;
}

export interface GroupPartnersFilter {
  partnerId?: number | null;
  partnerGroupId: number;
  countryId?: number | null;
  search?: string | null;
}

export interface InputMessenger {
  id: number;
  login: string;
}

export interface ManagerFullReportFilter {
  currencyId: number;
  startPeriod: string;
  endPeriod: string;
  partnerId?: number | null;
  partnersGroupId?: number | null;
  commisionGroupId?: number | null;
  registrationSourceId?: number | null;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedManagerFullReportType | null;
}

export interface ManagerQuickReportFilter {
  startPeriod: string;
  endPeriod: string;
  currencyId: number;
  commisionGroupId?: number | null;
  partnerId?: number | null;
  partnersGroupId?: number | null;
  promoId?: string | null;
}

export interface MarketingToolSelector {
  id?: number | null;
  name?: string | null;
}

export interface MarketingToolsReportFilter {
  startPeriod: string;
  endPeriod: string;
  currencyId: number;
  siteId?: number | null;
  marketingToolId?: string | null;
  marketingTool?: MarketingToolSelector | null;
  subId?: string | null;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedType | null;
  registrationSourceId?: number | null;
  methood: string;
}

export interface MediaFilter {
  mediaTypeId?: number | null;
  currencyId?: string | null;
  languageId?: number | null;
  campaignId?: number | null;
  mediaName?: string | null;
  width?: number | null;
  height?: number | null;
}

export interface MessengerAgentInput {
  id: number;
  credentials?: string | null;
}

export interface ModeratePostbackFilter {
  id: number;
  guid: string;
  partnerId: number;
  campaignId: number;
  statusId: number;
  siteId: number;
  internalId: number;
  typeRequestId: number;
  postbackTypeId: number;
  variable?: string | null;
  minSum?: number | null;
  minSumBet?: number | null;
  url: string;
  staticParameters?: string | null;
  isClickIdPostbackParameter: boolean;
  clickIdPostbackParameter?: string | null;
  isPartnerIdPostbackParameter: boolean;
  partnerIdPostbackParameter?: string | null;
  isTransaction: boolean;
  transaction?: string | null;
  isSiteIdPostbackParameter: boolean;
  siteIdPostbackParameter?: string | null;
  isOtherPostbackParameter: boolean;
  otherPostbackParameter?: string | null;
  isSumdepPostbackParameter: boolean;
  sumdepPostbackParameter?: string | null;
  childGuid?: string | null;
}

export interface NewCommissionGroupData {
  id: number;
  partnerId: number;
  noLimitedGroup: boolean;
  dateEnd?: string | null;
}

export interface NewPartner {
  login: string;
  password: string;
  firstname: string;
  surname: string;
  email: string;
  messenger?: NewPartnerMessenger | null;
  phone?: string | null;
  website?: string | null;
  country: number;
  language: number;
  note?: string | null;
  currencyId: number;
  commisionGroupId?: number | null;
  partnersGroupId?: number | null;
}

export interface NewPartnerGroup {
  partnerIds: number[];
  currencyId: number;
  name: string;
}

export interface NewPartnerMessenger {
  id: number;
  login: string;
}

export interface OrdersAndPaymentsFilter {
  currencyId: number;
  startPeriod: string;
  endPeriod: string;
}

export interface PaginationQueryParams {
  pageNumber?: number | null;
  countOnPage?: number | null;
  sorted?: SortedType | null;
}

export interface PartnerContactInfo {
  url: string;
  email: string;
  phoneNumber?: string | null;
  messenger?: MessengerAgentInput | null;
}

export interface PartnerGeneralInfo {
  note?: string | null;
  currencies: number[];
  status?: PartnerStatus | null;
  languageId: number;
  countryId: number;
  partnerId?: number | null;
  partnerSiteId?: number | null;
  promoCodeLimit?: number | null;
}

export interface PartnerInvoicesFilter {
  dateStart?: string | null;
  dateEnd?: string | null;
  currencyId: number;
  commissionGroupId?: number | null;
  partnerGroupId?: number | null;
  invoiceType?: PartnerInvoiceType | null;
  invoiceStatus?: PartnerInvoiceStatus | null;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedPartnerInvoicesType | null;
  search?: string | null;
}

export interface PartnerListFilter {
  currencyId?: number | null;
  partnersGroupId?: number | null;
  commisionGroupId?: number | null;
  languageId?: number | null;
  siteId?: number | null;
  promoCode?: string | null;
  registrationSourceId?: number | null;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedPartnerListType | null;
  statusId?: PartnerStatus | null;
  search?: string | null;
}

export interface PartnerPartnerGroupsParams {
  id: number;
  pageNumber?: number | null;
  countOnPage?: number | null;
  sorted?: SortedType | null;
}

export interface PartnersQueryParams {
  pageNumber?: number | null;
  countOnPage?: number | null;
  sorted?: SortedType | null;
}

export interface PaymentFieldInput {
  id: string;
  value?: string | null;
  validationTemplate?: string | null;
  required?: boolean | null;
  name?: string | null;
}

export interface PaymentFilesFilter {
  partnerId: number;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedPaymentFilesType | null;
}

export interface PaymentSystemInput {
  id: number;
  fields: PaymentFieldInput[];
  advertisingCompanyId?: number | null;
  countryId?: number | null;
}

export interface PaymentsHistoryFilter {
  currencyId: number;
  partnerId?: number | null;
  dateStart?: string | null;
  dateEnd?: string | null;
  commissionGroupId?: number | null;
  partnerGroupId?: number | null;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedPaymentsHistoryType | null;
  search?: string | null;
}

export interface PlansDescription {
  planId: number;
  paymentPeriod: PaymentPeriod;
  firstPaymentMonthDay: number;
  secondPaymentMonthDay?: number | null;
  createPaymentsAutomaticaly: boolean;
}

export interface PlayersActivityReportFilter {
  partnerId: number;
  siteId?: number | null;
  startDate: string;
  endDate: string;
  onlyNew: boolean;
}

export interface PlayersReportFilter {
  startPeriod: string;
  endPeriod: string;
  currencyId: number;
  siteId?: number | null;
  playerId?: number | null;
  campaign?: CampaignSelector | null;
  mediaId?: string | null;
  country?: string | null;
  withoutDepositsOnly?: boolean | null;
  onlyNewPlayers?: boolean | null;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedType | null;
  registrationSourceId?: number | null;
  subId?: string | null;
  methood: string;
}

export interface PostbackChange {
  siteId: number;
  campaignId: number;
  typeRequest: number;
  postbackType: number;
  variableNameInPartnerSystem: string;
  minSum?: string | null;
  minSumBet?: string | null;
  url: string;
  staticParameters?: string | null;
  isClickIdPostbackParameter: boolean;
  clickIdPostbackParameter?: string | null;
  isPartnerIdPostbackParameter: boolean;
  partnerIdPostbackParameter?: string | null;
  isTransactionPostbackParameter: boolean;
  transactionPostbackParameter?: string | null;
  isSiteIdPostbackParameter: boolean;
  siteIdPostbackParameter?: string | null;
  isOtherPostbackParameter: boolean;
  otherPostbackParameter?: string | null;
  isSumdepPostbackParameter: boolean;
  sumdepPostbackParameter?: string | null;
  childId?: number | null;
  comment?: string | null;
}

export interface PostbacksFilter {
  currencyId?: string | null;
  siteId?: number | null;
  partnersGroupId?: number | null;
  typeNameId?: number | null;
  status?: PostbackStatus | null;
}

export interface PostbacksParams {
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedPostbacksType | null;
  search?: string | null;
}

export interface PrepareForDownloadApkFilter {
  siteId: number;
  currencyId: number;
  campaignId: number;
}

export interface PromoCodeData {
  partnerId: number;
  currencyId: number;
  siteId: number;
  companyId: number;
  promoCode?: string | null;
}

export interface PromocodesFilter {
  partnerId: number;
  currencyId?: number | null;
  siteId?: number | null;
  promoCode?: string | null;
}

export interface QuickReportFilter {
  currencyId: number;
  startPeriod: string;
  endPeriod: string;
  siteId?: number | null;
  promoId?: string | null;
}

export interface ReferralDomainData {
  partnerId: number;
  currencyId: number;
  resource: string;
  hostName?: string | null;
  autoUpdate?: boolean | null;
  autoEmailSending?: boolean | null;
}

export interface ReferralDomainsParams {
  id: number;
  pageNumber?: number | null;
  countOnPage?: number | null;
  sorted?: SortedType | null;
}

export interface RegistrationInfo {
  login: string;
  password: string;
  firstname: string;
  surname: string;
  email: string;
  messenger?: InputMessenger | null;
  phone?: string | null;
  website?: string | null;
  websiteCategory?: number | null;
  country: number;
  language: number;
  howDidYouKnow?: number | null;
  paymentSystem: RegistrationPaymentSystem;
  rulesConfirmed: boolean;
  mailingConfirmed?: boolean | null;
  refId?: number | null;
}

export interface RegistrationPaymentSystem {
  id: number;
  fields?: RegistrationPaymentSystemField[] | null;
}

export interface RegistrationPaymentSystemField {
  id: string;
  value: string;
}

export interface ResetPasswordFilter {
  login: string;
  email: string;
  throughEmail?: boolean | null;
}

export interface SetOptionsData {
  enablePostBack: boolean;
  enableDomainApi: boolean;
  enablePaymentsAutomatically: boolean;
  enablePerMonthlyPayments: boolean;
  setCodeUsageLimit: number;
}

export interface SiteInput {
  name: string;
  siteCategoryId: number;
  languageId: number;
}

export interface SitesFilter {
  partnerId?: number | null;
  hidden?: boolean | null;
}

export interface SortedAdvertisementPaymentOrdersType {
  id: SortedAdvertisementPaymentOrdersColumn;
  desc: boolean;
}

export interface SortedManagerFullReportType {
  id: SortedManagerFullReportColumn;
  desc: boolean;
}

export interface SortedPartnerInvoicesType {
  id: SortedPartnerInvoicesColumn;
  desc: boolean;
}

export interface SortedPartnerListType {
  id: SortedPartnerListColumn;
  desc: boolean;
}

export interface SortedPaymentFilesType {
  id: SortedPaymentFilesColumn;
  desc: boolean;
}

export interface SortedPaymentsHistoryType {
  id: SortedPaymentsHistoryColumn;
  desc: boolean;
}

export interface SortedPostbacksType {
  id: SortedPostbacksColumn;
  desc: boolean;
}

export interface SortedType {
  id?: string | null;
  desc?: boolean | null;
}

export interface SubpartnerReportFilter {
  startPeriod: string;
  endPeriod: string;
  currencyId: number;
  countOnPage?: number | null;
  pageNumber?: number | null;
  sorted?: SortedType | null;
  methood: string;
  registrationDate?: string | null;
}

export interface TestimonialsParams {
  PageNumber?: number | null;
  PageSize?: number | null;
}

export interface UpdateAdvertisementPaymentParams {
  id: number;
  partnerId: number;
  statusId: AdvertisementStatus;
  dealType: number;
  siteId?: number | null;
  currencyId: number;
  amountRequest: number;
  placements?: ContractPlacement[] | null;
  payment: PaymentSystemInput;
  comments?: string | null;
  documentLinks?: string[] | null;
}

export interface UpdateAdvertisingPaymentFromFileFilter {
  walletNumber?: string | null;
  description?: string | null;
  sum: number;
  currencyId: number;
  documentLinks?: string[] | null;
  partnerId: number;
  paymentMethodId: number;
  siteId?: number | null;
  fileId: number;
  id: number;
}

export interface UpdatePartnerData {
  optionsData?: SetOptionsData | null;
  contactInfoData?: PartnerContactInfo | null;
  generalInfoData?: PartnerGeneralInfo | null;
}

export interface UpdatePartnerPartnerGroupData {
  id: number;
  partnerGroupId: number;
  endless: boolean;
  dateEnd?: string | null;
}

export interface UpdatePartnerPaymentMethodData {
  payment: AccountPaymentInput;
  advertisingCompanyId?: number | null;
  disable2faMainSite?: boolean | null;
}

export interface UpdatePaymentFileData {
  name: string;
  status?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
