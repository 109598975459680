import { TFunction } from "i18next";
import {
    GetCountries_countries,
    PartnerAccount_authorized_partnerAccount,
} from "gql/types/operation-result-types";
import { PopupType } from "./popup-id";
import { ReferencePopupData } from "./block-reference/reference-popup-data";
import { ConfirmEmailPopupData } from "./confirm-email/confirm-email-popup-data";
import { InfoBlockPopupData } from "./info-block/info-block-popup-data";
import { TelegramPopupData } from "./telegram/telegram-popup-data";
import { BannerPopupData } from "./banner/banner-popup-data";
import { PartnerBanner as PartnerBanner38 } from "./custom-banners/38_1/partner-banner";
import bannerBangladeshSrc from "assets/images/banner-modal/bangladesh.jpg";
import bannerEgyptSrc from "assets/images/banner-modal/egypt.jpg";
import bannerAfropariSrc from "assets/images/banner-modal/afropari.jpg";
import bannerGoldpariSrc from "assets/images/banner-modal/goldpari.jpg";
import bannerFunpariSrc from "assets/images/banner-modal/funpari.jpg";
import _1xPartnersBDSrc from "assets/images/banner-modal/1x-partners-bd.jpg";
import _1xPartnersleagueUZSrc from "assets/images/banner-modal/league-competition/uz.jpg";
import melbetBannerSrc from "assets/images/banner-modal/melbet.png";
import {
    getTelegramSrcByCountryId,
    TelegramBanner,
} from "./custom-banners/1_1/telegram-banner";
import {
    CountryId,
    LATAM_COUNTIRES_1XBET,
    LATAM_COUNTIRES_MELBET,
    getCountryCodesById,
} from "libs/country-id";
import _109WelcomeOfferSpSrc from "assets/images/banner-modal/109/1/welcome_offer_sp.png";
import _109WelcomeOfferRuSrc from "assets/images/banner-modal/109/1/welcome_offer_ru.png";
import _109WelcomeOfferEnSrc from "assets/images/banner-modal/109/1/welcome_offer_en.png";
import LPAFFtournamentSrc from "assets/images/banner-modal/1_1/LP_AFF_tournament.png";

const get109WelcomeOfferBanner = (lang: string | null) => {
    switch (lang) {
        case "ru":
            return _109WelcomeOfferRuSrc;
        case "es":
            return _109WelcomeOfferSpSrc;
        default:
            return _109WelcomeOfferEnSrc;
    }
};

export const getPopupsConfig = (
    currentGeoCountryCode: string | undefined,
    partnerAccountData: PartnerAccount_authorized_partnerAccount["mainAccountData"],
    countryCodes: Map<GetCountries_countries["id"], string>,
    __: TFunction,
    lang: string | null,
) => {
    return [
        new ReferencePopupData({
            id: 11,
            type: PopupType.BlockReference,
            disallowedAdAgentIds: ["7", "20", "10", "48", "30", "33", "57"],
            disallowedAffiliateIds: ["134", "142", "7009"],
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "3",
                name: "RUB",
            },
            title: __("Текущие партнерские ссылки вновь были заблокированы!"),
            /* prettier-ignore */
            description: __("Во избежание потери трафика, просим вас заменить все маркетинговые материалы на новые, как можно скорее. Ваши партнерские ссылки уже обновлены и находятся в разделе \"Маркетинговые инструменты\"."),
            infoText: __(
                "Обратите внимание, что домен рабочей партнерской ссылки должен быть:",
            ),
        }),

        new TelegramPopupData({
            id: 12,
            type: PopupType.Telegram,
            linkInPopup: "https://t.me/joinchat/AAAAAERxS0y4a4CAOX5EMA",
            timesToShow: 3,
            adAgentId: "1",
            materialGroupId: "1",
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "6",
                name: "USD",
            },
        }),
        new TelegramPopupData({
            id: 13,
            type: PopupType.Telegram,
            linkInPopup: "https://t.me/joinchat/AAAAAEMTyudFqq3oQ80RnQ",
            timesToShow: 3,
            adAgentId: "1",
            materialGroupId: "1",
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "3",
                name: "RUB",
            },
        }),
        new ConfirmEmailPopupData({
            id: 14,
            type: PopupType.ConfirmEmail,
            adAgentId: ["52", "57", "72"],
            partnerAccountData,
            currentGeoCountryCode,
            canBeClosed: true,
        }),
        new BannerPopupData({
            id: 16,
            type: PopupType.Banner,
            src: bannerBangladeshSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Bangladesh,
            ]),
            currentGeoCountryCode,
        }),
        new BannerPopupData({
            id: 18,
            type: PopupType.Banner,
            src: bannerEgyptSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [CountryId.Egypt]),
            currentGeoCountryCode,
        }),
        new BannerPopupData({
            id: 19,
            type: PopupType.Banner,
            src: bannerAfropariSrc,
            adAgentId: "67",
            timesToShow: 1,
        }),
        new BannerPopupData({
            id: 20,
            type: PopupType.Banner,
            src: bannerGoldpariSrc,
            adAgentId: "76",
            timesToShow: 1,
        }),
        new BannerPopupData({
            id: 21,
            type: PopupType.Banner,
            src: bannerFunpariSrc,
            adAgentId: "75",
            timesToShow: 1,
        }),
        new ReferencePopupData({
            id: 26,
            type: PopupType.BlockReference,
            adAgentId: ["30", "33"],
            currency: {
                __typename: "PartnerAndManagerCurrency",
                id: "6",
                name: "USD",
            },
            title: __("Текущие партнерские ссылки вновь были заблокированы!"),
            /* prettier-ignore */
            description: __("Во избежание потери трафика, просим вас заменить все маркетинговые материалы на новые, как можно скорее. Ваши партнерские ссылки уже обновлены и находятся в разделе \"Маркетинговые инструменты\"."),
            infoText: __(
                "Обратите внимание, что домен рабочей партнерской ссылки должен быть:",
            ),
        }),
        new InfoBlockPopupData({
            id: 27,
            type: PopupType.InfoBlock,
            adAgentId: "57",
            partnerAccountData,
        }),
        new BannerPopupData({
            id: 32,
            type: PopupType.CustomBanner,
            src: "",
            adAgentId: "38",
            materialGroupId: "1",
            currentGeoCountryCode,
            component: PartnerBanner38,
            showOnceADay: true,
        }),
        new ConfirmEmailPopupData({
            id: 33,
            type: PopupType.ConfirmEmail,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Uzbekistan,
                CountryId.India,
                CountryId.Turkey,
                CountryId.Bangladesh,
                ...LATAM_COUNTIRES_1XBET,
            ]),
            partnerAccountData,
            countryIds: [
                CountryId.Uzbekistan,
                CountryId.India,
                CountryId.Bangladesh,
                CountryId.Turkey,
                ...LATAM_COUNTIRES_1XBET,
            ],
            currentGeoCountryCode,
            canBeClosed: true,
        }),
        new ConfirmEmailPopupData({
            id: 35,
            type: PopupType.ConfirmEmail,
            adAgentId: "4",
            materialGroupId: "2",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Bangladesh,
                ...LATAM_COUNTIRES_MELBET,
            ]),
            partnerAccountData,
            countryIds: [CountryId.Bangladesh, ...LATAM_COUNTIRES_MELBET],
            currentGeoCountryCode,
            canBeClosed: false,
        }),
        new BannerPopupData({
            id: 36,
            type: PopupType.Banner,
            src: _1xPartnersBDSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Bangladesh,
            ]),
            currentGeoCountryCode,
            link: "https://casaff.top/L?tag=d_3603128m_72475c_&site=3603128&ad=72475",
            showOnceADay: true,
        }),
        new ConfirmEmailPopupData({
            id: 39,
            type: PopupType.ConfirmEmail,
            adAgentId: "4",
            materialGroupId: "1",
            partnerAccountData,
            currentGeoCountryCode,
            canBeClosed: true,
        }),
        new BannerPopupData({
            id: 53,
            type: PopupType.Banner,
            src: _1xPartnersleagueUZSrc,
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Uzbekistan,
            ]),
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 60,
            type: PopupType.CustomBanner,
            src:
                getTelegramSrcByCountryId(
                    countryCodes,
                    currentGeoCountryCode,
                ) || "",
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [
                CountryId.Malaysia,
                CountryId.Thailand,
                CountryId.Vietnam,
            ]),
            currentGeoCountryCode,
            component: TelegramBanner,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 61,
            type: PopupType.Banner,
            src: melbetBannerSrc,
            adAgentId: "4",
            countryCode: getCountryCodesById(countryCodes, [CountryId.Tunisia]),
            link: "https://t.me/Agents_Teamcash_Melbet",
            currentGeoCountryCode,
            showOnceADay: true,
        }),
        new BannerPopupData({
            id: 67,
            type: PopupType.Banner,
            src: get109WelcomeOfferBanner(lang),
            adAgentId: "109",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [CountryId.Peru]),
            currentGeoCountryCode,
            timesToShow: 1,
        }),
        new BannerPopupData({
            id: 68,
            type: PopupType.Banner,
            src: LPAFFtournamentSrc,
            link: "https://1xregister.co/ipr2025_pop",
            adAgentId: "1",
            materialGroupId: "1",
            countryCode: getCountryCodesById(countryCodes, [CountryId.India]),
            currentGeoCountryCode,
            timesToShow: 1,
        }),
    ];
};
